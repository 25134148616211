import { useTranslation } from "react-i18next";
import GridActionButton from "../commons/GridActionButton/GridActionButton";
import "../../styles/InProgressButtons.css";

export default function ManageRoleButtons(props) {
  // 'props' contain useful utilities and info sent by ag-grid
  const { t } = useTranslation();
  const { showRoleManagementModal, showRoleDeleteModal, data } = props;

  return (
    <div>
      <div className="inprogress-view-button">
        <GridActionButton variant="outlined" size="small" onClick={() => showRoleManagementModal(data, "edit")}>
          {t("COMMON:EDIT")}
        </GridActionButton>
      </div>
      <div className="inprogress-continue-button">
        <GridActionButton variant="outlined" size="small" onClick={() => showRoleDeleteModal(data)}>
          {t("COMMON:DELETE")}
        </GridActionButton>
      </div>
    </div>
  );
}
