import React from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function BasicAlert({ show, handleOK, message }) {
  return (
    <Modal open={show} onClose={handleOK}>
      <Box
        sx={{
          position: "absolute",
          width: 400,
          backgroundColor: "background.paper",
          boxShadow: 24,
          p: 4,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "20px",
        }}
      >
        <Typography variant={"h3"}>{message}</Typography>
        <Button variant="contained" onClick={handleOK}>
          OK
        </Button>
      </Box>
    </Modal>
  );
}

export default BasicAlert;
