import { useState, useEffect } from "react";

export const fetchEnvironmentVariables = async () => {
  const response = await fetch("/core/config/env");
  if (!response.ok) {
    throw new Error("Failed to fetch environment variables");
  }
  return response.json();
};

const useEnvironmentVariables = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const result = await fetchEnvironmentVariables();
      setData(result);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Return a promise that resolves when data is available
  return {
    data,
    error,
    isLoading,
  };
};

export default useEnvironmentVariables;
