import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

function NotFound() {
  const { t } = useTranslation();
  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          marginTop: "35vh",
        }}
      >
        <Typography variant="h1">404 {t("COMMON:NOT_FOUND")}</Typography>
      </Grid>
    </Grid>
  );
}
export default NotFound;
