import { useContext } from "react";

import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { Typography } from "@mui/material";

import { TabContext } from "./CustomTabs";

const IconContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "48px",
});

const TabNameContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
});

function AppGridTabIndicatorContent(props) {
  const { icon, tabName, progressStatus, index } = props; // 'index' is also being returned as a prop here
  // `activeIndex` can be taken from context
  const { activeIndex } = useContext(TabContext);

  const isTabActive = activeIndex === index;
  return (
    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", paddingRight: "1em" }}>
      <IconContainer>{icon}</IconContainer>
      <TabNameContainer>
        <Typography variant="body1">{tabName}</Typography>
        <Typography variant="body2" sx={{ fontStyle: "italic", color: isTabActive ? "text.primary" : "primary.dark" }}>
          {progressStatus}
        </Typography>
      </TabNameContainer>
    </Box>
  );
}

export default AppGridTabIndicatorContent;
