import React, { useState, useRef, useMemo, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./agGridStyleOverride.scss";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { formatTimestamp, isControlledByAutomation } from "../Utils";
import SpinnerContainer from "./SpinnerContainer";
import SearchBox from "../SearchBox/SearchBox";
import HttpContext from "../../../services/HttpContext";
import Snackbar from "../Snackbar";
import RoleManagementModal from "../../AccessManagement/RoleManagementModal";
import UserManagementModal from "../../AccessManagement/UserManagementModal";
import UserDeleteModal from "../../AccessManagement/UserDeleteModal";
import Constants from "../../../Constants";
import RoleDeleteModal from "../../AccessManagement/RoleDeleteModal";
import constants from "../../../Constants";

function FailedToLoadTickets() {
  const { t } = useTranslation();
  return (
    <div align="center">
      <h1>{t("TICKETS:FAILED_TO_LOAD")}</h1>
    </div>
  );
}

function TicketGrid(props) {
  const { t } = useTranslation();

  const {
    columnDefs,
    ticketStatusArray,
    ticketSubTypeArray,
    type,
    handleSnackBarChange,
    roleManagementModal,
    showRoleManagementModal,
    hideRoleManagementLogModal,
    modalData,
    userManagementModal,
    showUserManagementModal,
    hideUserManagementLogModal,
    userModalData,
    setUserModalData,
    userModalType,
    roleModalType,
    userDeleteModal,
    hideUserDeleteModal,
    userDeleteModalData,
    roleDeleteModal,
    hideRoleDeleteModal,
    roleDeleteModalData,
  } = props;

  const gridRef = useRef();
  const [data, setData] = useState(null);
  const [err, setErr] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const { GetRequest } = useContext(HttpContext);
  const pagination = !isControlledByAutomation(); // don't show pagination in automation testing

  // Fetch data when user clicks on "Approvals in side bar
  // NOTE - Other two tabs also make their API calls simultaneously
  useEffect(() => {
    fetchData();
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      autoHeight: true,
    };
  }, []);

  const onGridReady = (params) => {
    params.api.setDomLayout("autoHeight");
  };

  const onSearchQueryChange = (event) => {
    gridRef.current.api.setQuickFilter(event.target.value);
  };

  const handleSnackBarClose = (event, reason) => {
    // If the user clicks anywhere, then the snackbar should'nt close
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const fetchData = async () => {
    try {
      const subTypeFromUrl = window.location.pathname.split("/")[2];
      const subType = subTypeFromUrl ? subTypeFromUrl?.split("-")[0] : "helpdesk";

      let response;
      // TODO: GetAPI to fetch all users/roles
      if (type === Constants.ACCESS_MANAGEMENT.ROLE_MANAGEMENT) {
        response = await GetRequest(constants.REACT_APP_FETCH_ROLE);
        // TODO: format the response if doesnt comply with the columdefs
        setData(response);
      } else if (type === Constants.ACCESS_MANAGEMENT.USER_MANAGEMENT) {
        response = await GetRequest(constants.REACT_APP_FETCH_USER);
        const formattedData = [];
        Object.keys(response).forEach((key) => {
          formattedData.push({ userName: key, roles: response[key] });
        });
        setData(formattedData);
      } else {
        response = await GetRequest(
          `/core/${subType}/tickets?subtype=${ticketSubTypeArray}&status=${ticketStatusArray}`,
        );
        // Truncating date
        const formattedData = response.data
          ? response.data.map((row) => ({
              ...row,
              createdAt: formatTimestamp(row.createdAt),
              updatedAt: formatTimestamp(row.updatedAt),
            }))
          : [];
        setData(formattedData);
      }
    } catch {
      setErr(true);
      setSnackbarMsg("Failed to load tickets");
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      {data && (
        <>
          {type !== Constants.ACCESS_MANAGEMENT.ROLE_MANAGEMENT &&
            type !== Constants.ACCESS_MANAGEMENT.USER_MANAGEMENT && <SearchBox onChange={onSearchQueryChange} />}
          {type === Constants.ACCESS_MANAGEMENT.ROLE_MANAGEMENT && (
            <Box sx={{ backgroundColor: "primary.main" }}>
              <Button
                sx={{
                  backgroundColor: "primary.dark",
                  marginLeft: "15px",
                  marginTop: "15px",
                  marginBottom: "15px",
                  ":hover": {
                    bgcolor: "primary.light",
                    color: "primary.dark",
                  },
                }}
                onClick={showRoleManagementModal}
                size="medium"
              >
                {t("ROLE_MANAGEMENT:CREATE_ROLE")}
              </Button>
            </Box>
          )}
          {type === Constants.ACCESS_MANAGEMENT.USER_MANAGEMENT && (
            <Box sx={{ backgroundColor: "primary.main" }}>
              <Button
                sx={{
                  backgroundColor: "primary.dark",
                  margin: "10px",
                  ":hover": {
                    bgcolor: "primary.light",
                    color: "primary.dark",
                  },
                }}
                onClick={() => showUserManagementModal({ userName: "", roles: [] }, "assign")}
                size="medium"
              >
                {t("USER_MANAGEMENT:ASSIGN_USER")}
              </Button>
            </Box>
          )}
          <div className="ag-theme-material" style={{ width: "100%" }}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={data}
              pagination={pagination}
              paginationPageSize={20}
              onGridReady={onGridReady}
            />
          </div>
        </>
      )}
      {!data && !err && (
        <SpinnerContainer>
          <CircularProgress />
        </SpinnerContainer>
      )}
      {!data && err && <FailedToLoadTickets />}
      <Snackbar
        open={snackbarOpen}
        handleSnackBarClose={handleSnackBarClose}
        msg={snackbarMsg}
        vertical="top"
        horizontal="right"
        variant="failure"
      />
      {roleManagementModal && (
        <RoleManagementModal
          show={roleManagementModal}
          hideRoleManagementLogModal={hideRoleManagementLogModal}
          onHide={hideRoleManagementLogModal}
          handleSnackBarChange={handleSnackBarChange}
          fetchData={fetchData}
          data={modalData}
          roleModalType={roleModalType}
        />
      )}
      {userManagementModal && (
        <UserManagementModal
          show={userManagementModal}
          hideUserManagementLogModal={hideUserManagementLogModal}
          onHide={hideUserManagementLogModal}
          handleSnackBarChange={handleSnackBarChange}
          fetchData={fetchData}
          userModalData={userModalData}
          setUserModalData={setUserModalData}
          userModalType={userModalType}
        />
      )}
      {userDeleteModal && (
        <UserDeleteModal
          show={userDeleteModal}
          onHide={hideUserDeleteModal}
          data={userDeleteModalData}
          handleSnackBarChange={handleSnackBarChange}
          fetchData={fetchData}
        />
      )}
      {roleDeleteModal && (
        <RoleDeleteModal
          show={roleDeleteModal}
          onHide={hideRoleDeleteModal}
          data={roleDeleteModalData}
          handleSnackBarChange={handleSnackBarChange}
          fetchData={fetchData}
        />
      )}
    </>
  );
}

export default TicketGrid;
