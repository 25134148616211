import React, { useContext } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import DetailsActionButton from "../commons/DetailsActionButton/DetailsActionButton";
import HttpContext from "../../services/HttpContext";
import constants from "../../Constants";

function UserDeleteModal({ show, onHide, data, fetchData, handleSnackBarChange }) {
  const { DeleteRequest } = useContext(HttpContext);

  const modalContentContainerStyle = {
    backgroundColor: "primary.lightest",
    width: "70vw",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const titleContainerStyle = {
    display: "flex",
    flexDirection: "row",
    padding: "35px 35px 0px 35px",
    marginBottom: "10px",
  };

  const handleConfirm = async () => {
    const deleteUser = {
      userName: data.userName,
    };
    try {
      await DeleteRequest(constants.REACT_APP_DELETE_USER, deleteUser, null, true);
      handleSnackBarChange(true, "User deleted successfully", "success");
      fetchData();
    } catch (e) {
      handleSnackBarChange(true, e.message, "failure");
    }
    onHide();
  };

  return (
    <Modal open={show} onClose={onHide}>
      <Box sx={modalContentContainerStyle}>
        <Box sx={titleContainerStyle}>
          <Typography variant="h3">Are you sure you want to delete user {data.userName} ?</Typography>
          <CloseIcon sx={{ marginLeft: "auto", fontSize: "25px", cursor: "pointer" }} onClick={onHide} />
        </Box>
        <Box sx={{ padding: "35px" }}>
          <DetailsActionButton onClick={handleConfirm}>Confirm</DetailsActionButton>
          <DetailsActionButton onClick={onHide} sx={{ marginLeft: "1em" }}>
            Cancel
          </DetailsActionButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default UserDeleteModal;
