import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../services/UserContext";
import GridActionButton from "../../commons/GridActionButton/GridActionButton";
import { TabContext } from "../../Tabs/CustomTabs";
import "../../../styles/InProgressButtons.css";
import constants from "../../../Constants";

export function InProgressButtons(props) {
  // 'props' contain useful utilities and info sent by ag-grid
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { activeIndex } = useContext(TabContext);
  // This is the email address of current Admin user
  const {
    user: { user },
  } = useContext(UserContext);

  const ticketId = props?.data?.id;
  const appId = props?.data?.referenceId;

  // Show continue button to current admin user only if Ticket is assigned to them
  const showContinueButton = user === props.data.assignedTo;

  const handleNavigation = (readonly) => {
    const state = {
      readOnly: readonly,
      lastActiveTicketGridTabIndex: activeIndex,
    };
    navigate(`${constants.QUERY_TICKETS_URL}/ticket/${ticketId}/${appId}`, { state });
  };

  const handleViewClick = () => {
    handleNavigation(true);
  };
  const handleContinueClick = () => {
    handleNavigation(false);
  };

  return (
    <div>
      {showContinueButton ? (
        <div className="inprogress-continue-button">
          <GridActionButton variant="outlined" size="small" onClick={handleContinueClick}>
            {t("COMMON:CONTINUE")}
          </GridActionButton>
        </div>
      ) : (
        <div className="inprogress-view-button">
          <GridActionButton variant="outlined" size="small" onClick={handleViewClick}>
            {t("COMMON:VIEW")}
          </GridActionButton>
        </div>
      )}
    </div>
  );
}