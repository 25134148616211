import React from "react";
import { Typography, Stack } from "@mui/material";

const TicketDetailsRightSection = ({ label, data }) => {
  return (
    <Stack sx={{ marginBottom: "1.5rem" }}>
      <Typography sx={{ fontSize: "0.875rem", fontWeight: 400 }}>{label}</Typography>
      <Typography sx={{ fontSize: "0.875rem", fontWeight: 600}}>
        {data}
      </Typography>
    </Stack>
  );
};
export default TicketDetailsRightSection;