import React, { useState } from "react";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import "ag-grid-community/dist/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/dist/styles/ag-theme-material.css"; // Optional theme CSS
import Completed from "./_Completed/Completed";
import Unassigned from "./_Unassigned/Unassigned";
import InProgress from "./_InProgress/InProgress";

import CustomTabs from "../Tabs/CustomTabs";
import CustomTabIndicator from "../Tabs/CustomTabIndicator";
import CustomTabPanel from "../Tabs/CustomTabPanel";
import TicketGridTabIndicatorContent from "../Tabs/TicketGridTabIndicatorContent";
import CustomTabIndicatorContainer from "../Tabs/CustomTabIndicatorContainer";
import GridTitleContainer from "../commons/GridTitleContainer/GridTitleContainer";
import useTabTicketsCount from "../../hooks/useTabTicketsCount";
import Constants from "../../Constants";

const iconStyle = { fontSize: "28px" };

function TenantOnboardingGrid() {
  const { t } = useTranslation();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(location?.state?.lastActiveTicketGridTabIndex || 0);
  const ticketStatusCounts = useTabTicketsCount(Constants.SUB_TYPES.TENANT_ONBOARDING);

  return (
    <>
      <GridTitleContainer>Tenant onboarding</GridTitleContainer>
      <CustomTabs activeIndex={activeIndex} setActiveIndex={setActiveIndex}>
        <CustomTabIndicatorContainer>
          <CustomTabIndicator index={0}>
            <TicketGridTabIndicatorContent
              icon={<RadioButtonUncheckedIcon style={iconStyle} />}
              tabName={t("COMMON:UNASSIGNED")}
              index={0}
              ticketCount={ticketStatusCounts[Constants.TICKET_STATUS.UNASSIGNED]}
            />
          </CustomTabIndicator>
          <CustomTabIndicator index={1}>
            <TicketGridTabIndicatorContent
              icon={<PendingOutlinedIcon style={iconStyle} />}
              tabName={t("COMMON:IN_PROGRESS")}
              index={1}
              ticketCount={ticketStatusCounts[Constants.TICKET_STATUS.IN_PROGRESS]}
            />
          </CustomTabIndicator>
          <CustomTabIndicator index={2}>
            <TicketGridTabIndicatorContent
              icon={<CheckCircleIcon style={iconStyle} />}
              tabName={t("COMMON:COMPLETED")}
              index={2}
              ticketCount={
                ticketStatusCounts[Constants.TICKET_STATUS.RESOLVED] +
                ticketStatusCounts[Constants.TICKET_STATUS.CLOSED]
              }
            />
          </CustomTabIndicator>
        </CustomTabIndicatorContainer>

        <CustomTabPanel index={0}>
          <Unassigned />
        </CustomTabPanel>
        <CustomTabPanel index={1}>
          <InProgress />
        </CustomTabPanel>
        <CustomTabPanel index={2}>
          <Completed />
        </CustomTabPanel>
      </CustomTabs>
    </>
  );
}

export default TenantOnboardingGrid;
