const en = {
  COMMON: {
    SUBSCRIPTION_REQUEST: "Subscription request",
    REMOVE_LABEL: "Remove",
    CONTINUE: "Continue",
    CANCEL: "Cancel",
    CONFIRM: "Confirm",
    ACTIVITY_LOG: "Activity log",
    MESSAGES: "Messages",
    BACK: "Back",
    BACK_TO_CONSUMER: "Back to Consumer onboarding",
    BACK_TO_PROVIDER: "Back to Provider onboarding",
    BACK_TO_SUBSCRIPTION: "Back to Subscriptions",
    BACK_TO_DASHBOARD: "Back to Dashboard",
    REJECT: "Reject",
    REJECTED: "Rejected",
    VERIFY: "Verify",
    VERIFIED: "Verified",
    SAVE: "Save",
    COMMENT: "Comment",
    SELECT: "Select",
    MESSAGE: "Message",
    ASSIGN: "Assign",
    START: "Start",
    VIEW: "View",
    EDIT: "Edit",
    ERROR: "Error",
    OPEN: "Open",
    UNASSIGNED: "Unassigned",
    IN_PROGRESS: "In progress",
    COMPLETED: "Completed",
    AWAITING_INPUT: "Awaiting Input",
    ACTION_REQUESTED: "Action requested",
    RESOLVED: "Resolved",
    CLOSED: "Closed",
    SAVED: "Saved",
    TICKET_ID: "Ticket ID",
    SENDER: "Sender",
    CLOSE: "Close",
    ACTIVITY_LOGS: "Activity logs",
    DATE: "Date",
    USERNAME: "Username",
    EVENT: "Event",
    COMMENTS: "Comments",
    NO_MESSAGES: "No messages found for this ticket",
    NO_ACTIVITY_LOGS: "No activity logs found for this ticket",
    APP: "Application",
    FOR: "for",
    LOGIN_ERROR: "Failed to login",
    VERIFIED_SUCCESSFULLY: "Verified successfully",
    REJECTED_SUCCESSFULLY: "Rejected successfully",
    VERIFICATION_FAILED: "Verification failed",
    REJECTION_FAILED: "Rejection failed",
    HELP_DESK_TICKETS: "Help desk tickets",
    MY_ACCOUNT: "My account",
    COMING_SOON: "Coming soon",
    NOT_FOUND: "Not found",
    CREATE_NEW_TICKET: "Create a new ticket",
    BACK_TO_TICKETS: "Back to Tickets",
    TICKET_ID: "Ticket ID",
    AUTHORISED_USER1: "Authorised User 1",
    AUTHORISED_USER2: "Authorised User 2",
    EMAIL_ADDRESS_REPORTER: "Email address of the issue reporter",
    FIELD_REQUIRED: "This field is required",
    DESCRIPTION: "Description",
    RELAVANT_NAME: "relevant_name",
    SELECT_CUSTOMER_TYPE: "Please select customer type",
    TICKET_CREATED_FOR: "Ticket created for",
    SUBJECT: "Subject",
    SUBMIT: "Submit",
    CANCEL: "Cancel",
    PREMIUM_CUSTOMER: "Premium Customer",
    THIRD_PARTY_PROVIDER: "Third Party Provider",
    SELECT_CUSTOMER: "Select Customer",
    SELECT_THIRD_PARTY_PROVIDER: "Select Third Party Provider",
    NOT_AVAILABLE: "Not Available",
    TICKET_CREATED_SUCCESS: "Ticket created successfully",
    TICKETS: "Tickets",
    CLOSE_TICKET: "Close Ticket",
    TICKET_DETAILS: "Ticket Details",
    UNABLE_FETCH_PAU_DETAILS: "Unable To Fetch PAU Details",
    UNABLE_FETCH_TENANTS_PROVIDERS: "Unable to fetch tenants and providers data",
    INVALID_EMAIL: "Invalid Email Address",
    MAX_2048: "Maximum 2048 characters allowed",
    PLEASE_ADD_COMMENTS: "Please enter comments before closing the ticket",
    UNABLE_TO_CLOSE: "Unable to close the ticket",
    REQUEST_TIME_OUT:"Request Time Out"
  },
  APPLICATION: {
    PROVIDER_STATUS: "Provider status",
    PRODUCT_NAME: "Product name",
    ACCOUNT_ID: "Account ID",
    PROVIDER_NAME: "Provider name",
    PRODUCT_TYPE: "Product type",
    PRICING_PLAN: "Pricing plan",
    PLAN_TYPE: "Plan type",
    CREATED_BY: "Created by",
    IBAN: "IBAN",
    CURRENCY: "Currency",
    ACCOUNT_HOLDER_NAME: "Account holder name",

    REQUEST_FURTHER_INFORMATION: "Request further information",
    AWAITING_FURTHER_INFORMATION: "Awaiting information",
    REJECT_APPLICATION: "Reject application",
    APPLICATION_REJECTED: "Rejected",
    APPROVE_APPLICATION: "Approve application",
    APPLICATION_APPROVED: "Approved",
    BUSINESS_DETAILS: "Business details",
    BUSINESS_REPRESENTATIVES: "Business representatives",
    BUSINESS_OWNERS: "Business owners",
    BUSINESS_EXECUTIVES: "Business executives",
    BANK_ACCOUNT_DETAILS: "Bank account details",
    IDENTITY_DOCUMENTS: "Identity documents",
    ACCOUNT_DETAILS: "Account details",
    IN_PROGRESS: "In progress",
    COMPLETED: "Completed",
    COUNTRY: "Country",
    FOUNDATION_DATE: "Foundation date",
    PRIVACY_POLICY_URL: "Privacy policy URL",
    LEGAL_BUSINESS_NAME: "Legal business name",
    TYPE_OF_BUSINESS: "Type of business",
    BUSINESS_STRUCTURE: "Business structure",
    COMMERCIAL_REGISTRY: "Commercial registry",
    VAT_NUMBER: "VAT Number",
    DISTRICT_COURT: "District court",
    ADDRESS: "Address",
    WEBSITE: "Website",
    BUSINESS_DESCRIPTION: "Business description",
    INDUSTRY: "Industry",
    TURNOVER: "Turnover",
    EMAIL: "Email",
    PHONE: "Phone",
    CREATED_ON: "Created on",
    UPDATED_ON: "Updated on",
    DOCUMENTS_TO_BE_VERIFIED: "Documents to be verified",
    FAILED_TO_LOAD_DOCUMENT: "Failed to load document",
    OFFICIAL_EMAIL: "Official email",
    COMMENT_TYPES: {
      BUSINESS_DOCUMENT: "Business documents check",
      LEGAL_BUSINESS_NAME_CHECK: "Legal business name check",
      COUNTRY_RISK_CHECK: "Country risk check",
      IDENTITY_DOCUMENT: "Identity document",
      OFFICIAL_EMAIL_CHECK: "Official email check",
      BANK_ACCOUNT_DETAILS: "Bank account details",
      INDIVIDUAL_NAME_CHECK: "Individual name check",
      CUSTOMER_INFO_CHECK: "Verified the business information",
      EMAIL_TO_THIRD_PARTY_CHECK: "Sent an email to the vendor for the ID",
      ID_GENERATION_CHECK: "Received the ID",
    },
    TAX_CERTIFICATE: "Tax certificate",
    CERTIFICATION_REGISTRATION: "Certificate of registration",
    PASSPORT: "Passport",
    FURTHER_INFORMATION_REQUIRED: "Further information required",
    VERIFY_MESSAGE: "You are about to verify {{checkName}}. Please enter a comment and confirm.",
    REJECT_MESSAGE:
      "You are about to reject {{checkName}}. Please ensure you have entered valid comment before rejecting",
    APPROVE_APPLICATION_MESSAGE: "Please enter a comment and click on Confirm to approve the application",
    REJECT_APPLICATION_MESSAGE: "Please enter a rejection comment and click on Confirm to proceed",
    REQUEST_FURTHER_INFORMATION_MESSAGE:
      "You are about to send the application back to partner for further information. You have entered following comments. Please click on Confirm to proceed",
    LEFT_MENU: {
      ADMIN: "Admin",
      DASHBOARD: "Dashboard",
      PARTNERS: "Partners",
      SYSTEM_EVENTS: "System events",
      USERS: "Users",
      USER_MANAGEMENT: "User management",
      ROLE_MANAGEMENT: "Role management",
      SERVICE_REQUESTS: "Service requests",
      CONSUMER_ONBOARDING: "Consumer onboarding",
      PRODUCT_SUBSCRIPTION: "Product subscription",
      SUBSCRIPTION_REQUESTS: "Subscription requests",
      PARTNER_ONBOARDING: "Partner onboarding",
      ACCESS_MANAGEMENT: "Access management",
      CONSUMER: "Consumer",
      PROVIDER: "Provider",
      SUBSCRIPTIONS: "Subscriptions",
      HELP_DESK_TICKETS: "Help desk tickets",
      TENANT_ONBOARDING: "Tenant onboarding",
      MY_ACCOUNT: "My account",
      LOGOUT: "Logout",
      TICKETS: "Tickets",
    },
    FULL_NAME: "Full name",
    JOB_TITLE: "Job title",
    DATE_OF_BIRTH: "Date of birth",
    HOME_ADDRESS: "Home address",
    MESSAGE_BOX_PLACEHOLDER:
      "Please type the message/comment here. On save it will be captured in the Message history for future reference.",
    INVALID_TICKET_APP_ID_COMBINATION: "Application doesn't exist, navigating back to Service Request",
    VERIFICATION_TYPE: "Verification Type",
    COMPANY_NAME: "Company name",
    APPROVED: "Approved",
    REJECTED: "Rejected",
    ACTION_REQUIRED: "Action requested",
    VERIFIED: "Verified",
  },
  TICKETS: {
    FAILED_TO_LOAD: "Failed to load tickets",
    FAILED_TO_ASSIGN: "Failed to assign ticket",
    FAILED_TO_PERFORM_ACTION: "Failed to perform action on this ticket",
    SELECT_THE_USER: "Select the user",
    TYPE_TO_SEARCH: "Type to search...",
    FAILED_TO_FETCH: "Failed to fetch tickets",
    FULL_NAME: "Full name",
    JOB_TITLE: "Job title",
    DATE_OF_BIRTH: "Date of birth",
    HOME_ADDRESS: "Home Address",
    SEARCH: "Search",
    GRID_COLUMNS: {
      TICKET_NUMBER: "Ticket number",
      TYPE: "Type",
      CREATED_FOR: "Created for",
      CREATED_BY: "Created by",
      CREATED_AT: "Created at",
      CREATED_ON: "Created on (UTC)",
      UPDATED_ON: "Updated on (UTC)",
      ACTION: "Action",
      ASSIGNED_TO: "Assigned to",
      SLA: "SLA",
      PROVIDER_STATUS: "Provider status",
      STATUS: "Status",
      CUSTOMER_DETAILS: "Customer details",
      THIRD_PARTY_PROVIDER_DETAILS: "Third Party Provider details",
    },
  },
  ROLE_MANAGEMENT: {
    ROLE_NAME: "Role Name",
    CREATE_ROLE: "Create Role",
    EDIT_ROLE: "Edit Role",
    ROLE_CREATE_SUCCESS_MSG: "Role Created Successfully",
    ROLE_EDIT_SUCCESS_MSG: "Role Edited Successfully",
    ROLE_DELETE_SUCCESS_MSG: "Role Deleted Successfully",
    ROLE_ERROR_MSG: "Role can't be empty",
    RESOURCE_ERROR_MSG: "Resource can't be empty",
    PERMISSION_ERROR_MSG: "Permission can't be empty",
    FAILED_TO_PERFORM_ACTION: "Failed to perform action on this ticket",
    FORM_FIELD: {
      RESOURCE: "Resource",
      ROLE_NAME: "Role Name",
      PERMISSION: "Permission",
    },
    GRID_COLUMNS: {
      ROLE_NAME: "Role Name",
      RESOURCE: "Resource",
      PERMISSION: "Permission",
      ACTION: "Action",
    },
  },
  USER_MANAGEMENT: {
    GRID_COLUMNS: {
      USER_NAME: "User Name",
      ROLES: "Roles",
    },
    ASSIGN_USER: "Assign User",
    EDIT_USER: "Edit User",
    FORM_FIELD: {
      USER_NAME: "User Name",
      ROLE: "Role",
    },
    ERRORS: {
      USERNAME_NOT_VALID_EMAIL_ERROR: "Enter a valid email address",
      USERNAME_NOT_SYNTHIX_OR_BW_DOMAIN_ERROR: "User doesn't belong to synhtix or breakingwave",
      ROLE_ERROR: "Role can't be empty",
    },
    USER_EDIT_SUCCESS_MSG: "User Edited Successfully",
    USER_ASSIGN_SUCCESS_MSG: "User Assigned Successfully",
  },
  ERRORS: {
    BACKEND_ERROR: "Data inconsistency found, please try again.",
    REQUEST_FORBIDDEN: "Unauthorized",
  },
};

export default en;
