import { useState, useEffect, useContext } from "react";
import Constants from "../Constants";
import HttpContext from "../services/HttpContext";

const useTabTicketsCount = (serviceType) => {
  const { GetRequest } = useContext(HttpContext);
  const [ticketStatusCounts, setTicketStatusCounts] = useState({
    [Constants.TICKET_STATUS.UNASSIGNED]: 0,
    [Constants.TICKET_STATUS.IN_PROGRESS]: 0,
    [Constants.TICKET_STATUS.AWAITING_INPUT]: 0,
    [Constants.TICKET_STATUS.RESOLVED]: 0,
    [Constants.TICKET_STATUS.CLOSED]: 0,
  });
  useEffect(() => {
    /**
     * This function is fetching the number of ALL tickets of various types beloning to one servie Type
     */
    const fetchNumberOfPartnerOnboardingTickets = async () => {
      try {
        const subTypeFromUrl = window.location.pathname.split("/")[2];
        const subType = subTypeFromUrl ? subTypeFromUrl?.split("-")[0] : "helpdesk";

        const response = await GetRequest(
          `/core/${subType}/tickets?subtype=${serviceType}&status=${Constants.TICKET_STATUS.UNASSIGNED},${Constants.TICKET_STATUS.IN_PROGRESS},${Constants.TICKET_STATUS.AWAITING_INPUT},${Constants.TICKET_STATUS.RESOLVED},${Constants.TICKET_STATUS.CLOSED}`,
        );
        setTicketStatusCounts(
          // Iterating over array to get frequency map
          response.data.reduce(
            (acc, el) => {
              acc[el.status] += 1;
              return acc;
            },
            {
              [Constants.TICKET_STATUS.UNASSIGNED]: 0,
              [Constants.TICKET_STATUS.IN_PROGRESS]: 0,
              [Constants.TICKET_STATUS.AWAITING_INPUT]: 0,
              [Constants.TICKET_STATUS.RESOLVED]: 0,
              [Constants.TICKET_STATUS.CLOSED]: 0,
            },
          ),
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchNumberOfPartnerOnboardingTickets();
  }, [serviceType]);

  return ticketStatusCounts;
};

export default useTabTicketsCount;
