import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TicketGrid from "../commons/TicketGrid/TicketGrid";
import GridTitleContainer from "../commons/GridTitleContainer/GridTitleContainer";
import ManageRoleButtons from "./ManageRoleButton";
import Snackbar from "../commons/Snackbar";
import Constants from "../../Constants";

function RoleManagement() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("");
  const { t } = useTranslation();

  const handleSnackBarClose = (event, reason) => {
    // If the user clicks anywhere, then the snackbar should'nt close
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackBarChange = (_open, _msg, _variant) => {
    setSnackbarOpen(_open);
    setSnackbarMsg(_msg);
    setSnackbarVariant(_variant);
  };

  const [roleManagementModal, setRoleManagementModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [roleModalType, setRoleModalType] = useState(null);
  const [roleDeleteModal, setRoleDeleteModal] = useState(false);
  const [roleDeleteModalData, setRoleDeleteModalData] = useState(false);

  const showRoleManagementModal = (data, modalType) => {
    if (data) setModalData(data);
    setRoleManagementModal(true);
    setRoleModalType(modalType);
  };
  const hideRoleManagementLogModal = () => setRoleManagementModal(false);
  const hideRoleDeleteModal = () => setRoleDeleteModal(false);

  const showRoleDeleteModal = (data) => {
    if (data) setRoleDeleteModalData(data);
    setRoleDeleteModal(true);
  };

  const columnDefs = [
    {
      field: "roleName",
      headerName: t("ROLE_MANAGEMENT:GRID_COLUMNS.ROLE_NAME"),
      flex: 1,
    },
    {
      field: "resource",
      headerName: t("ROLE_MANAGEMENT:GRID_COLUMNS.RESOURCE"),
      flex: 1,
    },
    {
      field: "permission",
      headerName: t("ROLE_MANAGEMENT:GRID_COLUMNS.PERMISSION"),
      flex: 1,
    },
    {
      field: "action",
      headerName: t("ROLE_MANAGEMENT:GRID_COLUMNS.ACTION"),
      pinned: "right",
      cellRenderer: ManageRoleButtons,
      cellRendererParams: {
        handleSnackBarChange,
        showRoleManagementModal,
        showRoleDeleteModal,
      },
      flex: 1,
    },
  ];

  return (
    <>
      <GridTitleContainer>{t("APPLICATION:LEFT_MENU:ROLE_MANAGEMENT")}</GridTitleContainer>
      <TicketGrid
        columnDefs={columnDefs}
        type={Constants.ACCESS_MANAGEMENT.ROLE_MANAGEMENT}
        handleSnackBarChange={handleSnackBarChange}
        roleManagementModal={roleManagementModal}
        showRoleManagementModal={showRoleManagementModal}
        hideRoleManagementLogModal={hideRoleManagementLogModal}
        modalData={modalData}
        roleModalType={roleModalType}
        hideRoleDeleteModal={hideRoleDeleteModal}
        roleDeleteModal={roleDeleteModal}
        roleDeleteModalData={roleDeleteModalData}
      />
      <Snackbar
        open={snackbarOpen}
        handleSnackBarClose={handleSnackBarClose}
        msg={snackbarMsg}
        vertical="top"
        horizontal="right"
        variant={snackbarVariant}
      />
    </>
  );
}

export default RoleManagement;
