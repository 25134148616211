import React from "react";
import { I18nextProvider } from "react-i18next";
import reportWebVitals from "./reportWebVitals";
import { UserContextProvider } from "./services/UserContext";
import { createRoot } from "react-dom/client";
import "./index.css";
import "./reset.css";
import App from "./App";
import i18n from "./i18n";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

// Don't delete this log, it is used to identify which version is currently deployed.
console.log("Helpdesk Portal Version -", process.env.REACT_APP_VERSION);

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <UserContextProvider>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </UserContextProvider>
    </I18nextProvider>
  </React.StrictMode>,

  document.getElementById("root"),
);

reportWebVitals();
