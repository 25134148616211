import Grid from "@mui/material/Grid";

function VerificationItemContainer(props) {
  const { children } = props;
  return (
    <Grid container alignItems="center" marginY="10px">
      {children}
    </Grid>
  );
}

export default VerificationItemContainer;
