import { useContext, useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Login from "./components/Login/Login";
import LeftMenu from "./components/LeftMenu/LeftMenu";
import Dashboard from "./components/Dashboard/Dashboard";
import NotFound from "./components/NotFound/NotFound";
import Forbidden from "./components/Forbidden/Forbidden";
import UserContext from "./services/UserContext";
import { HttpContextProvider } from "./services/HttpContext";

import TenantDetails from "./components/TenantOnboarding/TenantDetails";
import ProductSubscriptionDetails from "./components/ProductSubscription/ProductSubscriptionDetails";

import RoleManangement from "./components/AccessManagement/RoleManagement";
import UserManangement from "./components/AccessManagement/UserManagement";

import mainTheme from "./themes/mainTheme";
import Constants from "./Constants";
import TenantOnboardingGrid from "./components/TenantOnboarding/TenantOnboardingGrid";
import ProductSubscriptionGrid from "./components/ProductSubscription/ProductSubscriptionGrid";
import { fetchEnvironmentVariables } from "./hooks/useEnvironmentalVariables";
import Ribbon from "./components/Ribbon/envRibbon";
import Tickets from "./components/QueryTicketsSection/DetailsSection/QueryTicketsGrid";
import TicketCreatedScreen from "./components/QueryTicketsSection/CreateTicketSection/TicketCreatedScreen";
import TicketsGrid from "./components/QueryTicketsSection/CreateTicketSection/CreateTicket";
import QueryTicketDetails from "./components/QueryTicketsSection/DetailsSection/QueryTicketDetails";

const routeContainerStyle = {
  flexGrow: 1,
  height: "100vh",
  width: "100%",
  overflow: "auto",
  marginLeft: "76px",
};

function Portal() {
  return (
    <ThemeProvider theme={mainTheme}>
      <Box sx={{ display: "flex" }}>
        <LeftMenu />
        <Box sx={routeContainerStyle}>
          <Routes>
            <Route path="/" element={<TenantOnboardingGrid />} />
            <Route path={Constants.DASHBOARD_URL} element={<Dashboard />} />
            <Route path={Constants.TENANT_ON_BOARDING_URL} element={<TenantOnboardingGrid />} />
            <Route path={Constants.TENANT_ON_BOARDING_URL + "/ticket/:ticketId/:appId"} element={<TenantDetails />} />
            <Route path={Constants.PRODUCT_SUBSCRIPTION_URL} element={<ProductSubscriptionGrid />} />
            <Route path={Constants.QUERY_TICKETS_URL} element={<Tickets />} />
            <Route path={Constants.QUERY_TICKETS_URL + "/ticket/:ticketId"} element={<TicketCreatedScreen />} />
            <Route path={Constants.QUERY_TICKETS_URL + "/create-ticket"} element={<TicketsGrid />} />
            <Route
              path={Constants.PRODUCT_SUBSCRIPTION_URL + "/ticket/:ticketId/:appId"}
              element={<ProductSubscriptionDetails />}
            />
            <Route
              path={Constants.QUERY_TICKETS_URL + "/ticket/:ticketId/:appId"}
              element={<QueryTicketDetails />}
            />
            <Route path={Constants.ROLE_MANAGEMENT_URL} element={<RoleManangement />} />
            <Route path={Constants.USER_MANAGEMENT_URL} element={<UserManangement />} />
            <Route path={Constants.FORBIDDEN_URL} element={<Forbidden />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

function App() {
  const { isUserLoggedIn, setEnvVariables, envVariables } = useContext(UserContext);
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);

  useEffect(() => {
    function onLoadHandler() {
      setIsDocumentLoaded(true);
    }
    const fetchData = async () => {
      try {
        const result = await fetchEnvironmentVariables();
        setEnvVariables(result);
      } catch (error) {
        setError(error);
      }
    };
    fetchData().then(() => onLoadHandler());
  }, []);
  return (
    <BrowserRouter>
      <HttpContextProvider>
        <Ribbon env={envVariables?.ENV} />
        {isDocumentLoaded && isUserLoggedIn() && <Portal />}
        {isDocumentLoaded && !isUserLoggedIn() && <Login />}
        {!isDocumentLoaded && <div>Loading...</div>}
      </HttpContextProvider>
    </BrowserRouter>
  );
}
export default App;
