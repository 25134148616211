import { styled } from "@mui/material";

import Button from "@mui/material/Button";

const GridActionButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.dark}`,
  color: theme.palette.primary.dark,
}));

export default GridActionButton;
