import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TicketGrid from "../../commons/TicketGrid/TicketGrid";
import UnassignedButtons from "./UnassignedButtons";
import Constants from "../../../Constants";
import "../../../styles/UnassignedButtons.css";
import Snackbar from "../../commons/Snackbar";
import { dateComparator } from "../../commons/Utils";

function ApprovalUnassigned() {
  const { t } = useTranslation();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("");

  const handleSnackBarClose = (event, reason) => {
    // If the user clicks anywhere, then the snackbar should'nt close
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackBarChange = (_open, _msg, _variant) => {
    setSnackbarOpen(_open);
    setSnackbarMsg(_msg);
    setSnackbarVariant(_variant);
  };

  const columnDefs = [
    {
      field: "id",
      headerName: t("TICKETS:GRID_COLUMNS.TICKET_NUMBER"),
      flex: 1,
    },
    {
      field: "createdBy",
      headerName: t("TICKETS:GRID_COLUMNS.CREATED_BY"),
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: t("TICKETS:GRID_COLUMNS.CREATED_ON"),
      sort: "desc",
      comparator: dateComparator,
      sortingOrder: ["desc", "asc"],
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: t("TICKETS:GRID_COLUMNS.UPDATED_ON"),
      sort: "desc",
      comparator: dateComparator,
      sortingOrder: ["desc", "asc"],
      flex: 1,
    },
    {
      field: "action",
      headerName: t("TICKETS:GRID_COLUMNS.ACTION"),
      pinned: "right", // Acton column is always visible to the right
      cellRenderer: UnassignedButtons,
      cellRendererParams: {
        handleSnackBarChange,
      },
    },
  ];

  return (
    <>
      <TicketGrid
        columnDefs={columnDefs}
        ticketStatusArray={[Constants.TICKET_STATUS.UNASSIGNED]}
        ticketSubTypeArray={[Constants.SUB_TYPES.PRODUCT_SUBSCRIPTION]}
      />
      <Snackbar
        open={snackbarOpen}
        handleSnackBarClose={handleSnackBarClose}
        msg={snackbarMsg}
        vertical="top"
        horizontal="right"
        variant={snackbarVariant}
      />
    </>
  );
}

export default ApprovalUnassigned;
