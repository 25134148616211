const REACT_APP_API_ROOT = "/core";
const REACT_APP_API_TICKETS = `${REACT_APP_API_ROOT}/tickets`;

const Constants = {
  ACTION_REQUIRED: "ACTION_REQUIRED",
  UNASSIGNED: "UNASSIGNED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  VERIFY: "VERIFY",
  VERIFIED: "VERIFIED",
  REJECT: "REJECT",
  REJECTED: "REJECTED",
  DASHBOARD: "DASHBOARD",
  DASHBOARD_URL: "/dashboard",
  CONSUMER_ON_BOARDING_URL: "/consumer-onboarding",
  PROVIDER_ON_BOARDING_URL: "/provider-onboarding",
  TENANT_ON_BOARDING_URL: "/tenant-onboarding",
  PRODUCT_SUBSCRIPTION_URL: "/product-subscription",
  QUERY_TICKETS_URL: "/query-tickets",
  USER_MANAGEMENT_URL: "/user-management",
  ROLE_MANAGEMENT_URL: "/role-management",
  SUBSCRIPTION_URL: "/subscription",
  USERS_URL: "/users",
  FORBIDDEN_URL: "/403",
  SYNTHIX: "synthix",
  BREAKINGWAVE: "breakingwave",
  ERRORS: {
    ERROR_DOC: "ERROR_DOC",
  },
  CommentTypes: {
    BUSINESS_DOCUMENT: "BUSINESS_DOCUMENT",
    LEGAL_BUSINESS_NAME_CHECK: "LEGAL_BUSINESS_NAME_CHECK",
    COUNTRY_RISK_CHECK: "COUNTRY_RISK_CHECK",
    INDIVIDUAL_NAME_CHECK: "INDIVIDUAL_NAME_CHECK",
    IDENTITY_DOCUMENT: "IDENTITY_DOCUMENT",
    BANK_ACCOUNT_DETAILS: "BANK_ACCOUNT_DETAILS",
    OFFICIAL_EMAIL_CHECK: "OFFICIAL_EMAIL_CHECK",
    OVERALL: "OVERALL",
    CUSTOMER_INFO_CHECK: "CUSTOMER_INFO_CHECK",
    EMAIL_TO_THIRD_PARTY_CHECK: "EMAIL_TO_THIRD_PARTY_CHECK",
    ID_GENERATION_CHECK: "ID_GENERATION_CHECK",
  },
  TICKET_STATUS: {
    UNASSIGNED: "OPEN",
    IN_PROGRESS: "IN_PROGRESS",
    AWAITING_INPUT: "AWAITING_INPUT",
    RESOLVED: "RESOLVED",
    CLOSED: "CLOSED",
  },
  INVALID_REFERENCE_ID: "INVALID_REFERENCE_ID",
  APPLICATION_APPROVAL_STATUS: {
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
    RETURNED: "RETURNED",
    VERIFIED: "VERIFIED",
  },
  APPLICATION_TABS: {
    BUSINESS_DETAILS: "BUSINESS_DETAILS",
    BUSINESS_REPRESENTATIVES: "BUSINESS_REPRESENTATIVES",
    BUSINESS_OWNERS: "BUSINESS_OWNERS",
    BUSINESS_EXECUTIVES: "BUSINESS_EXECUTIVES",
    BANK_ACCOUNT_DETAILS: "BANK_ACCOUNT_DETAILS",
  },
  SUB_TYPES: {
    CONSUMER_ON_BOARDING: "CONSUMER_ON_BOARDING",
    PROVIDER_ON_BOARDING: "PROVIDER_ON_BOARDING",
    PRODUCT_SUBSCRIPTION: "PRODUCT_SUBSCRIPTION",
    TENANT_ONBOARDING: "TENANT_ONBOARDING",
    QUERY: "HELPDESK_USER_QUERY",
  },
  BUSINESS_SUB_TYPE: {
    PRIVATE_CORPORATION: "PRIVATE_CORPORATION",
    UNINCORPORATED_ASSOCIATION: "UNINCORPORATED_ASSOCIATION",
    REGISTERED_ASSOCIATION: "REGISTERED_ASSOCIATION",
  },
  BUSINESS_RELATION_TYPE: {
    MANAGEMENT: "MANAGEMENT",
  },
  ACCESS_MANAGEMENT: {
    USER_MANAGEMENT: "USER_MANAGEMENT",
    ROLE_MANAGEMENT: "ROLE_MANAGEMENT",
  },

  REACT_APP_API_LOGIN: `${REACT_APP_API_ROOT}/users/login`,
  REACT_APP_API_LOGOUT: `${REACT_APP_API_ROOT}/users/logout`,
  REACT_APP_API_CONSUMER: `${REACT_APP_API_ROOT}/consumer`,
  REACT_APP_API_HELPDESK: `${REACT_APP_API_ROOT}/helpdesk`,
  REACT_APP_API_PROVIDER: `${REACT_APP_API_ROOT}/provider`,
  REACT_APP_API_SUBSCRIPTION: `${REACT_APP_API_ROOT}/subscription`,
  REACT_APP_API_TICKETS_SUBSCRIPTIONS: `${REACT_APP_API_TICKETS}/subscription`,
  REACT_APP_API_TICKETS_VERIFICATION: `${REACT_APP_API_TICKETS}/verification`,
  REACT_APP_TICKETS_BY_STATUS_URL: `${REACT_APP_API_TICKETS}/fetch/status`,
  REACT_APP_PROVIDER_APPLICATION_URL: `${REACT_APP_API_TICKETS}/provider/fetch-application`,
  REACT_APP_API_VERIFICATIONS: "verifications",
  REACT_APP_API_ACTION: "action",
  REACT_APP_API_MESSAGES: "messages",
  REACT_APP_API_ASSIGN: "assign",
  REACT_APP_API_ACTIVITY: "activity",
  REACT_APP_API_DOCUMENT: "document",
  REACT_APP_FETCH_ROLE: `${REACT_APP_API_ROOT}/access-mgmt/role`,
  REACT_APP_FETCH_ROLE_PERMISSION: `${REACT_APP_API_ROOT}/access-mgmt/resources-permissions`,
  REACT_APP_CREATE_ROLE: `${REACT_APP_API_ROOT}/access-mgmt/role`,
  REACT_APP_EDIT_ROLE: `${REACT_APP_API_ROOT}/access-mgmt/role`,
  REACT_APP_DELETE_ROLE: `${REACT_APP_API_ROOT}/access-mgmt/delete-role`,
  REACT_APP_FETCH_USER: `${REACT_APP_API_ROOT}/access-mgmt/user`,
  REACT_APP_CREATE_USER: `${REACT_APP_API_ROOT}/access-mgmt/user`,
  REACT_APP_EDIT_USER: `${REACT_APP_API_ROOT}/access-mgmt/user`,
  REACT_APP_DELETE_USER: `${REACT_APP_API_ROOT}/access-mgmt/unassign-user`,
};

export const CountryCodeToNameMapping = {
  in: "India",
  gb: "United Kingdom",
  de: "Germany",
};

export const snackbarMsgMapSuccess = {
  approve: "Approved Successfully",
  reject: "Rejected Successfully",
  further_info: "Successfully sent application back to partner",
};

export const snackbarMsgMapFailure = {
  approve: "Approval Failed",
  reject: "Rejection Failed",
  further_info: "Failed to send application back to partner",
};

export const roleManagementModalValues = {
  READ: "READ",
  READWRITE: "READWRITE",
  FULLACCESS: "FULLACCESS",
};

export const resources = ["TenantOnboarding", "Subscription", "All"];

export default Constants;
