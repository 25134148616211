import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const headerContainerStyle = {
  backgroundColor: "primary.light",
  paddingLeft: "20px",
  paddingTop: "15px",
  paddingBottom: "2em",
};

function GridTitleContainer(props) {
  const { children } = props;
  return (
    <Box sx={headerContainerStyle}>
      <Typography variant="h1" component="h1">
        {children}
      </Typography>
    </Box>
  );
}

export default GridTitleContainer;
