import React, { useContext } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import HttpContext from "../../services/HttpContext";
import DetailsActionButton from "../commons/DetailsActionButton/DetailsActionButton";
import constants from "../../Constants";

const modalContentContainerStyle = {
  backgroundColor: "primary.lightest",
  width: "70vw",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const titleContainerStyle = {
  display: "flex",
  flexDirection: "row",
  padding: "35px 35px 0px 35px",
  marginBottom: "10px",
};

function RoleDeleteModal({ show, onHide, data, fetchData, handleSnackBarChange }) {
  const { t } = useTranslation();
  const { DeleteRequest } = useContext(HttpContext);

  const handleConfirm = async () => {
    const deleteUser = {
      roleName: data.roleName,
    };
    try {
      await DeleteRequest(constants.REACT_APP_DELETE_ROLE, deleteUser, null, true);
      handleSnackBarChange(true, t("ROLE_MANAGEMENT:ROLE_DELETE_SUCCESS_MSG"), "success");
      fetchData();
    } catch (e) {
      handleSnackBarChange(true, e.message, "failure");
    }
    onHide();
  };

  return (
    <Modal open={show} onClose={onHide}>
      <Box sx={modalContentContainerStyle}>
        <Box sx={titleContainerStyle}>
          <Typography variant="h3">Are you sure you want to delete role {data.roleName} ?</Typography>
          <CloseIcon sx={{ marginLeft: "auto", fontSize: "25px", cursor: "pointer" }} onClick={onHide} />
        </Box>
        <Box sx={{ padding: "35px" }}>
          <DetailsActionButton onClick={handleConfirm} variant="outlined">
            Confirm
          </DetailsActionButton>
          <DetailsActionButton onClick={onHide} variant="outlined" sx={{ marginLeft: "1em" }}>
            Cancel
          </DetailsActionButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default RoleDeleteModal;
