import { createContext } from "react";

export const TabContext = createContext();

function CustomTabs(props) {
  const { activeIndex, setActiveIndex, children } = props;
  return <TabContext.Provider value={{ activeIndex, setActiveIndex }}>{children}</TabContext.Provider>;
}

export default CustomTabs;
