import React, { useState, useEffect, useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Error from "@mui/icons-material/Error";
import Checkbox from "@mui/material/Checkbox";
import DetailsGrid from "../../commons/DetailsGrid/DetailsGrid";
import ApplicationDetailsPaper from "../../commons/ApplicationDetailsPaper/ApplicationDetailsPaper";
import VerificationIconContainer from "../../commons/VerificationIconContainer/VerificationIconContainer";
import AppDetailsContentContainer from "../../commons/AppDetailsContentContainer";
import DetailsTitleContainer from "../../commons/DetailsTitleContainer";
import CircularProgress from "@mui/material/CircularProgress";
import { formatDateToSystemDesignFormat, addToDetailMap } from "../../commons/Utils";
import constants from "../../../Constants";
import HttpContext from "../../../services/HttpContext";
import UserContext from "../../../services/UserContext";
import Snackbar from "../../commons/Snackbar";

const convertToStatus = (isVerified) => {
  return isVerified ? "VERIFIED" : "REJECTED";
};

function ProductBusinessDetails({
  verifications,
  ticketId,
  applicationData,
  readOnly,
  refetchVerifications,
  setApproveDisabled,
}) {
  const { t } = useTranslation();
  const tableMap = useMemo(() => {
    let tableDetailsMap = new Map([]);
    addToDetailMap(
      applicationData?.data?.subscriptionDetails?.productConfig?.legalEntityName,
      "Legal entity name",
      tableDetailsMap,
    );

    addToDetailMap(
      applicationData?.data?.subscriptionDetails?.productConfig?.sponsorBankIfsc,
      "Sponsor Bank IFSC",
      tableDetailsMap,
    );
    addToDetailMap(
      applicationData?.data?.subscriptionDetails?.productConfig?.utilityCode,
      "Utility code",
      tableDetailsMap,
    );

    addToDetailMap(applicationData?.data?.subscriptionDetails?.tenantIdentifier, "Tenant ID", tableDetailsMap);

    addToDetailMap(applicationData?.data?.subscriptionDetails?.productCode, "Product code", tableDetailsMap);

    addToDetailMap(applicationData?.data?.pauBusinessEmails, "PAU email address", tableDetailsMap);

    addToDetailMap(applicationData?.data?.subscriptionDetails?.requestedBy, "Created by", tableDetailsMap);

    addToDetailMap(
      formatDateToSystemDesignFormat(
        new Date(
          applicationData?.data?.subscriptionDetails?.updatedAt?.seconds * 1000 +
            Math.round(applicationData?.data?.subscriptionDetails?.updatedAt?.nanos / 1000000),
        ),
      ),
      "Created at",
      tableDetailsMap,
    );

    return tableDetailsMap;
  }, [applicationData]);
  const {
    user: { user },
  } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const { REACT_APP_API_HELPDESK } = constants;
  const { PostRequest } = useContext(HttpContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("");

  const [checklistArray, setChecklistArray] = useState([
    { type: constants.CommentTypes.CUSTOMER_INFO_CHECK, value: false },
    { type: constants.CommentTypes.EMAIL_TO_THIRD_PARTY_CHECK, value: false },
    { type: constants.CommentTypes.ID_GENERATION_CHECK, value: false },
  ]);

  useEffect(() => {
    checklistArray.forEach((item) => {
      const verificationType = item.type;
      const matchingVerifications = verifications?.approvals?.filter(
        (approval) => approval.verificationType === verificationType,
      )[0];
      const isVerified = constants.VERIFIED.toString() === matchingVerifications?.verificationStatus.toString();

      setChecklistArray((prevArray) =>
        prevArray.map((prevItem) => {
          if (prevItem.type === item.type) {
            return { ...prevItem, value: isVerified };
          }
          return prevItem;
        }),
      );
    });
  }, [verifications]);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleCheckboxChange = (index) => async () => {
    setLoading(true);
    try {
      const changingVerificationTo = !checklistArray[index].value;
      const verificationRequest = {
        applicationId: applicationData?.applicationId,
        assignedTo: user,
        approval: {
          verificationType: checklistArray[index].type,
          verificationStatus: convertToStatus(changingVerificationTo),
        },
      };

      setChecklistArray((prevArray) => {
        const newArray = [...prevArray];
        newArray[index].value = changingVerificationTo;
        return newArray;
      });

      await PostRequest(`${REACT_APP_API_HELPDESK}/${ticketId}/verifications`, verificationRequest);
      setSnackbarVariant("success");
      setSnackbarMsg("Success");
      refetchVerifications();
    } catch (e) {
      setSnackbarMsg(e.toString);
      setSnackbarVariant("failure");
    }
    setSnackbarOpen(true);
    setLoading(false);
  };

  useEffect(() => {
    const allTrue = checklistArray.every((item) => item.value === true);
    setApproveDisabled(!allTrue);
  }, checklistArray);

  return (
    <>
      <DetailsTitleContainer>{t("APPLICATION:BUSINESS_DETAILS")}</DetailsTitleContainer>
      <AppDetailsContentContainer>
        <Box sx={{ flex: 1 }}>
          {Object.keys(tableMap).map((item, idx) => (
            <DetailsGrid key={idx} map={tableMap} item={item} />
          ))}
        </Box>
        <Box sx={{ flex: 1 }}>
          <Box>
            <ApplicationDetailsPaper elevation={0}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <VerificationIconContainer>
                  <Error sx={{ fontSize: "1.5em" }} />
                </VerificationIconContainer>
                <Typography variant="h3" component="span">
                  Checklist
                </Typography>
              </Box>

              <Box marginTop="1.5em">
                <Typography>I confirm that I've</Typography>
              </Box>

              <Box marginTop="1em">
                {checklistArray.map((item, index) => (
                  <Box key={index} sx={{ display: "flex", alignItems: "center", m: "0.5em 0" }}>
                    {loading ? (
                      <CircularProgress size="24px" />
                    ) : (
                      <Checkbox
                        sx={{
                          p: 0,
                          m: 0,
                          "&.Mui-checked": {
                            color: "primary.darker",
                          },
                        }}
                        disabled={readOnly}
                        checked={item.value}
                        onChange={handleCheckboxChange(index)}
                      />
                    )}
                    <Typography sx={{ ml: "0.5rem" }}>{t(`APPLICATION:COMMENT_TYPES.${item.type}`)}</Typography>
                  </Box>
                ))}
              </Box>
            </ApplicationDetailsPaper>
          </Box>
        </Box>
        <Snackbar
          open={snackbarOpen}
          handleSnackBarClose={handleSnackBarClose}
          msg={snackbarMsg}
          vertical="top"
          horizontal="right"
          variant={snackbarVariant}
        />
      </AppDetailsContentContainer>
    </>
  );
}

export default ProductBusinessDetails;
