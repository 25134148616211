import React, { useState, useContext } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { useNavigate, useLocation } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import SvgIcon from "@mui/material/SvgIcon";

import { useTranslation } from "react-i18next";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { dontForwardTheseProps } from "../../functions/dontForwardTheseProps";
import Constants from "../../Constants";
import HttpContext from "../../services/HttpContext";
import UserContext from "../../services/UserContext";
import ListItemExpand from "../ListItemExpand/ListItemExpand";
import constants from "../../Constants";

import { ReactComponent as NavIcon } from "../../assets/navicon.svg";

const StyledListItemButton = styled(ListItemButton)({
  padding: "0",
  marginBottom: "12px",
});

const StyledUpperNavbarListButton = styled(ListItemButton)({
  padding: "0",
  paddingTop: "15px",
  paddingBottom: "15px",
});

const StyledListItemIcon = styled(ListItemIcon)({
  width: "76px",
  display: "flex",
  justifyContent: "center",
});

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  flex: "0 1 auto",

  "& .MuiListItemText-primary": {
    color: theme.palette.primary.lightest,
  },
}));

const ListContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
});

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => dontForwardTheseProps(["currentLocation", "destinations"], prop),
})(({ color, backgroundColor }) => {
  return { backgroundColor, color, fontSize: "24px", padding: "4px" };
});

const computeColor = (destinations, currentLocation, theme) => {
  let pathBelongsToDestinations = false;
  let color;

  destinations.forEach((path) => {
    if (currentLocation === path) {
      pathBelongsToDestinations = true;
    }
  });

  if (pathBelongsToDestinations) {
    color = theme.palette.primary.darker;
  } else {
    color = theme.palette.primary.light;
  }
  return color;
};

const computeBackgroundColor = (destinations, currentLocation, theme) => {
  let pathBelongsToDestinations = false;
  let backgroundColor;

  destinations.forEach((path) => {
    if (currentLocation.includes(path)) {
      pathBelongsToDestinations = true;
    }
  });

  if (pathBelongsToDestinations) {
    backgroundColor = theme.palette.primary.lightest;
  } else {
    backgroundColor = theme.palette.primary.dark;
  }
  return backgroundColor;
};

function LeftMenuItems({ open, setOpen }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { clearUser } = useContext(UserContext);
  const { PostRequest } = useContext(HttpContext);
  const logout = () => {
    PostRequest(constants.REACT_APP_API_LOGOUT).finally(() => {
      clearUser();
      navigate("/");
    });
  };

  const navigateToRoleManagement = () => {
    navigate(Constants.ROLE_MANAGEMENT_URL);
    setOpen(false);
  };
  const navigateToUserManagement = () => {
    navigate(Constants.USER_MANAGEMENT_URL);
    setOpen(false);
  };

  const navigateToProductOnboardingTickets = () => {
    navigate(Constants.PRODUCT_SUBSCRIPTION_URL);
    setOpen(false);
  };
  const navigateToQueryTickets = () => {
    navigate(Constants.QUERY_TICKETS_URL);
    setOpen(false);
  };
  const navigateToTenantOnboardingTickets = () => {
    navigate(Constants.TENANT_ON_BOARDING_URL);
    setOpen(false);
  };
  const navigateToMyAccount = () => {
    navigate("/account");
    setOpen(false);
  };

  const [onboardingExpanded, setOnboardingExpanded] = useState(false);
  const [accessManagementExpanded, setaccessManagementExpanded] = useState(false);

  if (!open && onboardingExpanded) {
    setOnboardingExpanded(false);
  }

  if (!open && accessManagementExpanded) {
    setaccessManagementExpanded(false);
  }

  const toggleManagementAccess = () => {
    if (!open) {
      setOpen(true);
    }
    setaccessManagementExpanded((prev) => !prev);
  };

  const upperNavbarItems = [
    {
      onClick: navigateToTenantOnboardingTickets,
      location: Constants.TENANT_ON_BOARDING_URL,
      title: "APPLICATION:LEFT_MENU.TENANT_ONBOARDING",
    },
    {
      onClick: navigateToProductOnboardingTickets,
      location: Constants.PRODUCT_SUBSCRIPTION_URL,
      title: "APPLICATION:LEFT_MENU.PRODUCT_SUBSCRIPTION",
    },
    {
      onClick: navigateToQueryTickets,
      location: Constants.QUERY_TICKETS_URL,
      title: "APPLICATION:LEFT_MENU.TICKETS",
    },
  ];

  const accessManagementItems = [
    {
      onClick: navigateToUserManagement,
      title: "APPLICATION:LEFT_MENU.USER_MANAGEMENT",
    },
    {
      onClick: navigateToRoleManagement,
      title: "APPLICATION:LEFT_MENU.ROLE_MANAGEMENT",
    },
  ];

  return (
    <ListContainer>
      <Box>
        {upperNavbarItems.map((item) => (
          <StyledUpperNavbarListButton onClick={item.onClick}>
            <StyledListItemIcon>
              <StyledIconButton
                sx={{
                  color: computeColor([item.location], location.pathname, theme),
                  backgroundColor: computeBackgroundColor([item.location], location.pathname, theme),
                }}
              >
                <SvgIcon component={NavIcon} />
              </StyledIconButton>
            </StyledListItemIcon>
            <StyledListItemText primary={t(item.title)} />
          </StyledUpperNavbarListButton>
        ))}
      </Box>
      <Box>
        <StyledListItemButton onClick={toggleManagementAccess} sx={{ display: "flex" }}>
          <ListItemExpand onboardingExpanded={accessManagementExpanded} />
          <StyledListItemIcon>
            <StyledIconButton
              sx={{
                color: computeColor(
                  [Constants.ROLE_MANAGEMENT_URL, Constants.USER_MANAGEMENT_URL],
                  location.pathname,
                  theme,
                ),
                backgroundColor: computeBackgroundColor(
                  [Constants.ROLE_MANAGEMENT_URL, Constants.USER_MANAGEMENT_URL],
                  location.pathname,
                  theme,
                ),
              }}
            >
              <Badge color="primary">
                <ManageAccountsIcon />
              </Badge>
            </StyledIconButton>
          </StyledListItemIcon>
          <StyledListItemText primary={t("APPLICATION:LEFT_MENU.ACCESS_MANAGEMENT")} />
        </StyledListItemButton>
        <Collapse in={accessManagementExpanded}>
          <List>
            {accessManagementItems.map((item) => (
              <StyledListItemButton onClick={item.onClick}>
                <StyledListItemText primary={t(item.title)} sx={{ marginLeft: "86px" }} />
              </StyledListItemButton>
            ))}
          </List>
        </Collapse>
        <StyledListItemButton onClick={navigateToMyAccount}>
          <StyledListItemIcon>
            <StyledIconButton
              sx={{
                color: computeColor(["/account"], location.pathname, theme),
                backgroundColor: computeBackgroundColor(["/account"], location.pathname, theme),
              }}
            >
              <SettingsIcon />
            </StyledIconButton>
          </StyledListItemIcon>
          <StyledListItemText primary={t("APPLICATION:LEFT_MENU.MY_ACCOUNT")} />
        </StyledListItemButton>
        <StyledListItemButton onClick={logout}>
          <StyledListItemIcon>
            <StyledIconButton sx={{ color: theme.palette.primary.light }}>
              <LogoutIcon />
            </StyledIconButton>
          </StyledListItemIcon>
          <StyledListItemText primary={t("APPLICATION:LEFT_MENU.LOGOUT")} />
        </StyledListItemButton>
      </Box>
    </ListContainer>
  );
}

export default LeftMenuItems;

function NotificationCount({ theme, count }) {
  return (
    <Box sx={{ marginLeft: "auto", marginRight: "16px", color: theme.palette.primary.light }}>
      <Typography variant="body1">{count}</Typography>
    </Box>
  );
}
