import React, { useState, useEffect, useContext } from "react";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import BasicTable from "../Table";
import HttpContext from "../../../services/HttpContext";
import { activityLogsDateComparator, formatTimestamp, activityLogsDescendingComparator } from "../Utils";
import constants from "../../../Constants";

const modalContentContainerStyle = {
  backgroundColor: "primary.lightest",
  width: "70vw",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const titleContainerStyle = {
  display: "flex",
  flexDirection: "row",
  padding: "35px 35px 0px 35px",
  marginBottom: "10px",
};

function ActivityLogsModal({ show, onHide, ticketId }) {
  const { GetRequest } = useContext(HttpContext);
  const { t } = useTranslation();
  const { REACT_APP_API_ACTIVITY, REACT_APP_API_HELPDESK } = constants;

  const [logs, setLogs] = useState(null);
  const fetchLogs = async () => {
    try {
      const response = await GetRequest(`${REACT_APP_API_HELPDESK}/${ticketId}/${REACT_APP_API_ACTIVITY}`);
      const formattedActivityLogs = response.data.map((log) => {
        const date = log.createdAt;
        const username = log.actorId;
        const event = log.eventType;
        const comment = `${username} triggered an action  ${log.description}`;
        return {
          date,
          username,
          event,
          comment,
        };
      });
      setLogs(formattedActivityLogs ?? []);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (show) {
      fetchLogs();
    }
  }, [show, ticketId]);

  const columns = [
    {
      name: t("COMMON:DATE"),
      dataAttr: "date",
      formatter: formatTimestamp,
      sort: "desc",
      sorter: activityLogsDateComparator,
    },
    { name: t("COMMON:USERNAME"), dataAttr: "username", sort: "desc", sorter: activityLogsDescendingComparator },
    { name: t("COMMON:EVENT"), dataAttr: "event", sort: "desc", sorter: activityLogsDescendingComparator },
    { name: t("COMMON:COMMENT"), dataAttr: "comment", sort: "desc", sorter: activityLogsDescendingComparator },
  ];

  return (
    <Modal open={show} onClose={onHide}>
      <Box sx={modalContentContainerStyle}>
        <Box sx={titleContainerStyle}>
          <Typography variant="h1">
            {t("COMMON:ACTIVITY_LOGS")} ({logs?.length})
          </Typography>
          <CloseIcon sx={{ marginLeft: "auto", fontSize: "25px", cursor: "pointer" }} onClick={onHide} />
        </Box>
        <Box>
          <Typography variant="h3" sx={{ paddingLeft: "35px" }}>
            {t("COMMON:TICKET_ID")} {ticketId}
          </Typography>
        </Box>
        <Box sx={{ padding: "35px" }}>
          {logs && logs.length > 0 && <BasicTable data={logs} columns={columns} />}
          {!logs && <CircularProgress />}
          {logs && logs.length == 0 && <Box padding="35px">{t("COMMON:NO_ACTIVITY_LOGS")}</Box>}
        </Box>
      </Box>
    </Modal>
  );
}

export default ActivityLogsModal;
