import { styled } from "@mui/material";
import Box from "@mui/material/Box";

const VerificationIconContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  margin: "0px 0.5em 0px 0.5em",
});

export default VerificationIconContainer;
