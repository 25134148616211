import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Constants from "../../Constants";
import HttpContext from "../../services/HttpContext";
import UserContext from "../../services/UserContext";
import constants from "../../Constants";
import useEnvironmentVariables from "../../hooks/useEnvironmentalVariables";

export default function Login() {
  const navigate = useNavigate();
  const { LoginRequest } = useContext(HttpContext);
  const { saveUser } = useContext(UserContext);
  const { data: environmentVariables } = useEnvironmentVariables();

  function handleGoogleSigninCallbackResponse(response) {
    const jwtToken = response.credential;

    LoginRequest(constants.REACT_APP_API_LOGIN, jwtToken)
      .then(() => {
        saveUser(jwtToken);
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (Object.prototype.hasOwnProperty.call(params, "redirectTo")) {
          navigate(params.redirectTo);
        } else {
          navigate(Constants.TENANT_ON_BOARDING_URL);
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  }

  useEffect(() => {
    if (!window.google || !environmentVariables || environmentVariables.error) {
      return;
    }

    window.google.accounts.id.initialize({
      client_id: environmentVariables.REACT_APP_GSI_CLIENT_ID,
      callback: handleGoogleSigninCallbackResponse,
    });

    window.google.accounts.id.renderButton(document.getElementById("google-signin-btn-container"), {
      theme: "filled_blue",
      size: "large",
    });
  }, [environmentVariables]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div id="google-signin-btn-container" />
    </div>
  );
}
