import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function ApplicationStatusContainer(props) {
  const { children, ...rest } = props;
  return (
    <Box
      backgroundColor="primary.darker"
      paddingX="0.5em"
      paddingY="0.25em"
      borderRadius="5px"
      minWidth="5em"
      width="max-content"
      textAlign="center"
      marginLeft="15px"
      {...rest}
    >
      <Typography variant="body1" sx={{ color: "primary.light" }}>
        {children}
      </Typography>
    </Box>
  );
}

export default ApplicationStatusContainer;
