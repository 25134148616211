import React, { useEffect, useContext } from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import HttpContext from "../../services/HttpContext";
import constants, { roleManagementModalValues, resources } from "../../Constants";

function RoleManagementModal({
  show,
  onHide,
  hideRoleManagementLogModal,
  data,
  handleSnackBarChange,
  fetchData,
  roleModalType,
}) {
  const theme = useTheme();
  const [prevRole, setPrevRole] = React.useState("");
  const [role, setRole] = React.useState("");
  const { t } = useTranslation();
  const [resource, setResource] = React.useState([]);
  const [permission, setPermission] = React.useState("");
  const { PostRequest, PutRequest } = useContext(HttpContext);
  const [fieldsValid, setFieldsValid] = React.useState({
    isRoleValid: false,
    isResourceValid: false,
    ispermissionValid: false,
  });
  const [fieldsMsg, setFieldsMsg] = React.useState({
    roleErrMsg: "",
    resourceErrMsg: "",
    permissionErrMsg: "",
  });
  const setState = (key, val) => {
    setFieldsValid((prevFieldsValid) => ({ ...prevFieldsValid, [key]: val }));
  };
  const setMsgState = (key, val) => {
    setFieldsMsg((prevFieldsMsg) => ({ ...prevFieldsMsg, [key]: val }));
  };

  useEffect(() => {
    if (data?.roleName) setPrevRole(data?.roleName);
    if (data?.roleName) setRole(data?.roleName);
    if (data?.resource) setResource(data.resource);
    if (data?.permission) setPermission(data?.permission);
  }, [data]);

  const handleRoleChange = (event) => {
    const val = event.target.value;
    setRole(val);
    if (val !== "") {
      setState("isRoleValid", false);
      setMsgState("roleErrMsg", "");
    }
  };

  const handleResourceChange = (event) => {
    const val = event.target.value;
    setResource(
      // On autofill we get a stringified value.
      typeof val === "string" ? val.split(",") : val,
    );
    if (val.length > 0) {
      setState("isResourceValid", false);
      setMsgState("resourceErrMsg", "");
    }
  };

  const handlePermissionChange = (event) => {
    const val = event.target.value;
    setPermission(val);
    if (val !== "") {
      setState("ispermissionValid", false);
      setMsgState("permissionErrMsg", "");
    }
  };

  const handleConfirm = async () => {
    const createRole = {
      prevRoleName: prevRole,
      roleName: role,
      resources: resource,
      permission,
    };
    try {
      if (roleModalType === "edit") {
        await PutRequest(constants.REACT_APP_EDIT_ROLE, createRole);
      } else {
        await PostRequest(constants.REACT_APP_CREATE_ROLE, createRole);
      }
      handleSnackBarChange(
        true,
        roleModalType === "edit"
          ? t("ROLE_MANAGEMENT:ROLE_EDIT_SUCCESS_MSG")
          : t("ROLE_MANAGEMENT:ROLE_CREATE_SUCCESS_MSG"),
        "success",
      );
      fetchData();
    } catch (e) {
      handleSnackBarChange(true, e.message, "failure");
    }
    hideRoleManagementLogModal();
  };

  const saveRole = () => {
    if (role === "") {
      setState("isRoleValid", true);
      setMsgState("roleErrMsg", t("ROLE_MANAGEMENT:ROLE_ERROR_MSG"));
    }
    if (resource.length === 0) {
      setState("isResourceValid", true);
      setMsgState("resourceErrMsg", t("ROLE_MANAGEMENT:RESOURCE_ERROR_MSG"));
    }
    if (permission === "") {
      setState("ispermissionValid", true);
      setMsgState("permissionErrMsg", t("ROLE_MANAGEMENT:PERMISSION_ERROR_MSG"));
    }
    if (role !== "" && resource.length !== 0 && permission !== "") {
      handleConfirm();
    }
    return null;
  };

  const modalContentContainerStyle = {
    backgroundColor: "primary.lightest",
    width: "70vw",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const titleContainerStyle = {
    display: "flex",
    flexDirection: "row",
    padding: "35px 35px 0px 35px",
    marginBottom: "10px",
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(_resource, resource) {
    return {
      fontWeight:
        resource.indexOf(_resource) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
      backgroundColor: resource.indexOf(_resource) === -1 ? theme.palette.primary.lighter : theme.palette.primary.light,
    };
  }

  return (
    <Modal open={show} onClose={onHide}>
      <Box sx={modalContentContainerStyle}>
        <Box sx={titleContainerStyle}>
          <Typography variant="h1">
            {roleModalType === "edit" ? t("ROLE_MANAGEMENT:EDIT_ROLE") : t("ROLE_MANAGEMENT:CREATE_ROLE")}
          </Typography>
          <CloseIcon sx={{ marginLeft: "auto", fontSize: "25px", cursor: "pointer" }} onClick={onHide} />
        </Box>
        <Box sx={{ padding: "35px" }}>
          <FormControl fullWidth sx={{ padding: "10px" }}>
            <TextField
              value={role}
              id="outlined-basic"
              label={t("ROLE_MANAGEMENT:FORM_FIELD.ROLE_NAME")}
              onChange={handleRoleChange}
              error={fieldsValid.isRoleValid}
              helperText={fieldsMsg.roleErrMsg}
            />
          </FormControl>
          <FormControl fullWidth sx={{ padding: "10px" }} error={fieldsValid.isResourceValid}>
            <InputLabel sx={{ marginLeft: "10px", marginTop: "10px" }}>
              {t("ROLE_MANAGEMENT:FORM_FIELD:RESOURCE")}
            </InputLabel>
            <Select
              multiple
              value={resource}
              onChange={handleResourceChange}
              input={<OutlinedInput label="Resources" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {resources.map((_resource) => (
                <MenuItem key={_resource} value={_resource} style={getStyles(_resource, resource)}>
                  {_resource}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{fieldsMsg.resourceErrMsg}</FormHelperText>
          </FormControl>
          <FormControl fullWidth sx={{ padding: "10px" }} error={fieldsValid.ispermissionValid}>
            <InputLabel sx={{ marginLeft: "10px", marginTop: "10px" }}>
              {t("ROLE_MANAGEMENT:FORM_FIELD.PERMISSION")}
            </InputLabel>
            <Select value={permission} label="Permissions" onChange={handlePermissionChange}>
              <MenuItem value={roleManagementModalValues.READ}>READ</MenuItem>
              <MenuItem value={roleManagementModalValues.READWRITE}>READWRITE</MenuItem>
              <MenuItem value={roleManagementModalValues.FULLACCESS}>FULLACCESS</MenuItem>
            </Select>
            <FormHelperText>{fieldsMsg.permissionErrMsg}</FormHelperText>
          </FormControl>
          <Box sx={{ padding: "10px" }}>
            <Button sx={{ backgroundColor: "primary.dark" }} onClick={saveRole}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default RoleManagementModal;
