import React from "react";
import { Typography } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";

const TicketDetailsLeftSection = ({ label, data }) => {
  return (
    <Table>
      <TableBody>
        <TableRow sx={{ display: "flex" }}>
          <TableCell sx={{ flex: "1" }}>
            <Typography sx={{ fontSize: "0.875rem", fontWeight: 400 }}>{label}</Typography>
          </TableCell>
          <TableCell sx={{ flex: "1" }}>
            <Typography sx={{ fontSize: "0.875rem", fontWeight: 600 }}>{data}</Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
export default TicketDetailsLeftSection;