import { useContext } from "react";
import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/system";
import { TabContext } from "./CustomTabs";

import { dontForwardTheseProps } from "../../functions/dontForwardTheseProps";

const StyledButtonBase = styled(ButtonBase, {
  shouldForwardProp: (prop) => dontForwardTheseProps(["activeIndex", "index"], prop),
})(({ theme, activeIndex, index }) => ({
  minWidth: "222px",
  height: "72px",
  display: "flex",
  flexDirection: "row",
  borderRight: `1px solid ${theme.palette.primary.main}`,
  "&:hover": {
    cursor: "pointer",
  },
  backgroundColor: activeIndex === index ? theme.palette.primary.main : theme.palette.primary.light,
}));

function CustomTabIndicator(props) {
  const { activeIndex, setActiveIndex } = useContext(TabContext);
  const { children, index } = props;
  return (
    <StyledButtonBase
      onClick={() => setActiveIndex(index)}
      role="button"
      tabIndex={0}
      activeIndex={activeIndex}
      index={index}
    >
      {children}
    </StyledButtonBase>
  );
}

export default CustomTabIndicator;
