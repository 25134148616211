import { useContext } from "react";
import { TabContext } from "./CustomTabs";

function CustomTabPanel(props) {
  const { children, index } = props;
  const { activeIndex } = useContext(TabContext);

  return (
    <div
      role="tabpanel"
      hidden={activeIndex !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {activeIndex === index && children}
    </div>
  );
}
export default CustomTabPanel;
