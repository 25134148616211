import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TicketGrid from "../commons/TicketGrid/TicketGrid";
import GridTitleContainer from "../commons/GridTitleContainer/GridTitleContainer";
import ManageUserButtons from "./ManageUserButton";
import Snackbar from "../commons/Snackbar";
import Constants from "../../Constants";

function UserManangement() {
  const { t } = useTranslation();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("");

  const handleSnackBarClose = (event, reason) => {
    // If the user clicks anywhere, then the snackbar should'nt close
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleSnackBarChange = (_open, _msg, _variant) => {
    setSnackbarOpen(_open);
    setSnackbarMsg(_msg);
    setSnackbarVariant(_variant);
  };

  const [userManagementModal, setUserManagementModal] = useState(false);
  const [userDeleteModal, setUserDeleteModal] = useState(false);
  const [userDeleteModalData, setUserDeleteModalData] = useState(false);
  const [userModalData, setUserModalData] = useState(null);
  const [userModalType, setUserModalType] = useState(null);

  const showUserManagementModal = (data, modalType) => {
    if (data) setUserModalData(data);
    setUserManagementModal(true);
    setUserModalType(modalType);
  };
  const showUserDeleteModal = (data) => {
    if (data) setUserDeleteModalData(data);
    setUserDeleteModal(true);
  };
  const hideUserManagementLogModal = () => setUserManagementModal(false);
  const hideUserDeleteModal = () => setUserDeleteModal(false);

  const columnDefs = [
    {
      field: "userName",
      headerName: t("USER_MANAGEMENT:GRID_COLUMNS.USER_NAME"),
      flex: 1,
    },
    {
      field: "roles",
      headerName: t("USER_MANAGEMENT:GRID_COLUMNS.ROLES"),
      flex: 1,
    },
    {
      field: "action",
      headerName: t("ROLE_MANAGEMENT:GRID_COLUMNS.ACTION"),
      pinned: "right",
      cellRenderer: ManageUserButtons,
      flex: 1,
      cellRendererParams: {
        handleSnackBarChange,
        showUserManagementModal,
        showUserDeleteModal,
      },
    },
  ];

  return (
    <>
      <GridTitleContainer>{t("APPLICATION:LEFT_MENU:USER_MANAGEMENT")}</GridTitleContainer>
      <TicketGrid
        columnDefs={columnDefs}
        type={Constants.ACCESS_MANAGEMENT.USER_MANAGEMENT}
        handleSnackBarChange={handleSnackBarChange}
        userManagementModal={userManagementModal}
        showUserManagementModal={showUserManagementModal}
        hideUserManagementLogModal={hideUserManagementLogModal}
        userModalData={userModalData}
        setUserModalData={setUserModalData}
        userModalType={userModalType}
        hideUserDeleteModal={hideUserDeleteModal}
        userDeleteModal={userDeleteModal}
        userDeleteModalData={userDeleteModalData}
      />
      <Snackbar
        open={snackbarOpen}
        handleSnackBarClose={handleSnackBarClose}
        msg={snackbarMsg}
        vertical="top"
        horizontal="right"
        variant={snackbarVariant}
      />
    </>
  );
}
export default UserManangement;
