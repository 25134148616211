import { useContext } from "react";
import "../../../styles/CompletedButtons.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GridActionButton from "../../commons/GridActionButton/GridActionButton";
import { TabContext } from "../../Tabs/CustomTabs";
import constants from "../../../Constants";

export function CompletedButtons(props) {
  // 'props' contain useful utilities and info sent by ag-grid
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { activeIndex } = useContext(TabContext);

  const ticketId = props?.data?.id;
  const appId = props?.data?.referenceId;

  const handleNavigation = (readonly) => {
    const state = {
      readOnly: readonly,
      lastActiveTicketGridTabIndex: activeIndex,
    };
    navigate(`${constants.QUERY_TICKETS_URL}/ticket/${ticketId}/${appId}`, { state });
  };

  const handleViewClick = () => handleNavigation(true);

  return (
    <div className="completed-view-button">
      <GridActionButton variant="outlined" size="small" onClick={handleViewClick}>
        {t("COMMON:VIEW")}
      </GridActionButton>
    </div>
  );
}