import { createTheme } from "@mui/material/styles";

const mainTheme = createTheme({
  typography: {
    fontFamily: "Lato",
    fontSize: 14,
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    h1: {
      fontSize: "2.28571428571rem",
    },
    h2: {
      fontSize: "1.42857142857rem",
    },
    h3: {
      fontSize: "1.14285714286rem",
    },
  },
  palette: {
    primary: {
      lightest: "#FFFFFF",
      lighter: "#F8F9FA",
      light: "#E3E3E3",
      main: "#D0D0D0",
      dark: "#818181",
      darker: "#404040",
    },
    text: {
      primary: "#3e3e3e",
    },
  },
});

export default mainTheme;
