import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import { Typography, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HttpContext from "../../services/HttpContext";
import BasicAlert from "../commons/Toast/BasicAlert";
import { useScrollToTop } from "../../hooks/useScrollTopTop";
import DetailsActionButton from "../commons/DetailsActionButton/DetailsActionButton";
import TenantActionSection from "./ActionSection/TenantActionSection";
import CustomTabs from "../Tabs/CustomTabs";
import CustomTabIndicatorContainer from "../Tabs/CustomTabIndicatorContainer";
import CustomTabIndicator from "../Tabs/CustomTabIndicator";
import AppGridTabIndicatorContent from "../Tabs/AppGridTabIndicatorContent";
import CustomTabPanel from "../Tabs/CustomTabPanel";
import Snackbar from "../commons/Snackbar";

import BusinessDetails from "./DetailSection/BusinessDetails";
import BusinessRepresentatives from "./DetailSection/BusinessRepresentatives";

import Constants, { snackbarMsgMapSuccess, snackbarMsgMapFailure } from "../../Constants";

import useFindProgressForTab from "../../hooks/useFindProgressForTab";
import constants from "../../Constants";

const iconStyle = { fontSize: "28px" };

function TenantDetails() {
  useScrollToTop();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { PostRequest, GetRequest } = useContext(HttpContext);
  const [activeIndex, setActiveIndex] = useState(0);
  const { ticketId, appId } = useParams();
  const { REACT_APP_API_HELPDESK } = constants;
  const lastActiveTicketGridTabIndex = location?.state?.lastActiveTicketGridTabIndex || 0;

  const [applicationData, setApplicationData] = useState(null);
  const [verifications, setVerifications] = useState();
  const [overallVerified, setOverallVerified] = useState("");

  const [show, setShow] = useState(false);
  const [basicAlertShow, setBasicAlertShow] = useState(false);

  const [applicationApprovalButtonState, setApplicationApprovalButtonState] = useState("");
  const [readOnly, setReadOnly] = useState(location?.state?.readOnly || applicationApprovalButtonState?.length > 0);
  const [applicationApprovalReadOnly, setApplicationApprovalReadOnly] = useState(true);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarTempMsg, setSnackbarTempMsg] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSnackBarClose = (event, reason) => {
    // If the user clicks anywhere, then the snackbar shouldn't close
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const loadData = async () => {
    try {
      const response = await GetRequest(`${REACT_APP_API_HELPDESK}/${ticketId}/${appId}`, null, true);
      const responseJson = await response.json();
      setReadOnly(response.headers.get("readonly") === "true" || readOnly);
      setApplicationData({
        data: responseJson,
        applicationId: parseInt(appId, 10),
      });
    } catch (err) {
      console.log(err);
      setErrorMessage(err.toString());
      setBasicAlertShow(true);
    }
  };

  const handleBasicAlertOk = () => navigate(constants.DASHBOARD_URL);

  const loadVerifications = async () => {
    try {
      const response = await GetRequest(`${REACT_APP_API_HELPDESK}/${ticketId}/verifications`);
      const overallVerification = response?.approvals?.find((approval) => approval.verificationType === "OVERALL");
      const overallStatus = overallVerification ? overallVerification.verificationStatus : null;
      setOverallVerified(overallStatus);
      setVerifications(response);
      // setComments(response?.approvals?.filter((item) => "comment" in item && item?.comment !== ""));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!applicationData) {
      loadData();
    }
  }, [applicationData]);

  useEffect(() => {
    if (!verifications) {
      loadVerifications();
      // if overall verification value exists, disable application level verification buttons
      const matchingVerifications = verifications?.approvals?.filter(
        (item) => item.verificationStatus === Constants.CommentTypes.OVERALL,
      )[0];
      const overallVerificationState = matchingVerifications?.verificationStatus;
      if (overallVerificationState?.length) {
        setReadOnly(true);
      }
    }
    if (verifications?.approvals?.length) {
      setApplicationApprovalReadOnly(
        !(
          verifications?.approvals[0]?.verificationStatus === Constants.VERIFIED &&
          verifications?.approvals[1]?.verificationStatus === Constants.VERIFIED &&
          verifications?.approvals[2]?.verificationStatus === Constants.VERIFIED
        ),
      );
    }
  }, [verifications]);

  const handleClose = () => {
    setShow(false);
    setApplicationApprovalButtonState("");
    setARComment("");
  };

  const [loadingConfirmButton, setLoadingConfirmButton] = useState(false);

  const [applicationApprovalRejectionComment, setARComment] = React.useState("");

  const handleConfirm = async () => {
    setLoadingConfirmButton(true);
    try {
      const adminActionRequest = {
        action: applicationApprovalButtonState,
        comment: applicationApprovalRejectionComment,
      };
      await PostRequest(`${REACT_APP_API_HELPDESK}/${ticketId}/action`, adminActionRequest);
      setSnackbarOpen(true);
      setSnackbarMsg(snackbarMsgMapSuccess[snackbarTempMsg]);
      setSnackbarVariant("success");
      setReadOnly(true);
    } catch (e) {
      setSnackbarOpen(true);
      setSnackbarMsg(snackbarMsgMapFailure[snackbarTempMsg]);
      setSnackbarVariant("failure");
      setApplicationApprovalButtonState("");
    } finally {
      loadVerifications();
      setLoadingConfirmButton(false);
      setShow(false);
    }
  };
  const [modalData, setModalData] = useState(null);

  const handleShow = (header, body) => {
    setModalData({ header, body });
    setShow(true);
  };

  const handleRejectAppShow = () => {
    setApplicationApprovalButtonState(Constants.APPLICATION_APPROVAL_STATUS.REJECTED);
    handleShow(t("APPLICATION:REJECT_APPLICATION"), t("APPLICATION:REJECT_APPLICATION_MESSAGE"));
    setSnackbarTempMsg("reject");
  };
  const handleApproveAppShow = () => {
    setApplicationApprovalButtonState(Constants.APPLICATION_APPROVAL_STATUS.APPROVED);
    handleShow(t("APPLICATION:APPROVE_APPLICATION"), t("APPLICATION:APPROVE_APPLICATION_MESSAGE"));
    setSnackbarTempMsg("approve");
  };

  const isTabComplete = useFindProgressForTab(verifications, applicationData);

  const computeProgressStatus = (tabType) => {
    return isTabComplete[tabType] ? t("COMMON:COMPLETED") : t("COMMON:IN_PROGRESS");
  };

  const iconBasedOnProgress = (tabType) => {
    return isTabComplete[tabType] ? (
      <CheckCircleIcon style={iconStyle} sx={{ color: "primary.darker" }} />
    ) : (
      <RadioButtonUncheckedIcon style={iconStyle} />
    );
  };

  return (
    <>
      <BasicAlert
        handleOK={handleBasicAlertOk}
        setShow={setBasicAlertShow}
        show={basicAlertShow}
        message={errorMessage}
      />
      <Modal open={show} onClose={handleClose}>
        <ModalContentContainer>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography variant="h3">{modalData?.header}</Typography>
            <CloseIcon sx={{ fontSize: "25px", marginLeft: "auto" }} onClick={handleClose} />
          </Box>
          <Box>
            <Box sx={{ paddingTop: "35px" }}>
              <Typography variant="body1">{modalData?.body}</Typography>
            </Box>
            <Box sx={{ paddingTop: "25px" }}>
              <textarea
                style={{ width: "100%" }}
                value={applicationApprovalRejectionComment}
                onChange={(e) => setARComment(e.target.value)}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              paddingTop: "35px",
            }}
          >
            <DetailsActionButton variant="outlined" size="small" onClick={handleClose}>
              {t("COMMON:CANCEL")}
            </DetailsActionButton>
            <Box sx={{ marginLeft: "1em" }}>
              <DetailsActionButton
                variant="outlined"
                size="small"
                onClick={handleConfirm}
                loading={loadingConfirmButton}
                disabled={applicationApprovalRejectionComment?.trim() === ""}
              >
                {t("COMMON:CONFIRM")}
              </DetailsActionButton>
            </Box>
          </Box>
        </ModalContentContainer>
      </Modal>
      <TenantActionSection
        readOnly={readOnly}
        approveApplicationReadOnly={applicationApprovalReadOnly}
        overallVerified={overallVerified}
        applicationData={applicationData}
        handleRejectAppShow={handleRejectAppShow}
        handleApproveAppShow={handleApproveAppShow}
        applicationApprovalButtonState={applicationApprovalButtonState}
        lastActiveTicketGridTabIndex={lastActiveTicketGridTabIndex}
      />
      <Box>
        <CustomTabs activeIndex={activeIndex} setActiveIndex={setActiveIndex}>
          <CustomTabIndicatorContainer>
            <CustomTabIndicator index={0}>
              <AppGridTabIndicatorContent
                icon={iconBasedOnProgress(Constants.APPLICATION_TABS.BUSINESS_DETAILS)}
                tabName={t("APPLICATION:BUSINESS_DETAILS")}
                progressStatus={computeProgressStatus(Constants.APPLICATION_TABS.BUSINESS_DETAILS)}
                index={0}
              />
            </CustomTabIndicator>
            <CustomTabIndicator index={1}>
              <AppGridTabIndicatorContent
                icon={iconBasedOnProgress(Constants.APPLICATION_TABS.BUSINESS_REPRESENTATIVES)}
                tabName={t("APPLICATION:BUSINESS_REPRESENTATIVES")}
                progressStatus={computeProgressStatus(Constants.APPLICATION_TABS.BUSINESS_REPRESENTATIVES)}
                index={1}
              />
            </CustomTabIndicator>
          </CustomTabIndicatorContainer>

          <CustomTabPanel index={0}>
            <BusinessDetails
              verifications={verifications}
              refetchVerifications={loadVerifications}
              ticketId={parseInt(ticketId, 10)}
              applicationData={applicationData}
              readOnly={readOnly}
            />
          </CustomTabPanel>
          <CustomTabPanel index={1}>
            <BusinessRepresentatives
              verifications={verifications}
              refetchVerifications={loadVerifications}
              ticketId={parseInt(ticketId, 10)}
              applicationData={applicationData}
              readOnly={readOnly}
            />
          </CustomTabPanel>
        </CustomTabs>
      </Box>
      <Snackbar
        open={snackbarOpen}
        handleSnackBarClose={handleSnackBarClose}
        msg={snackbarMsg}
        vertical="top"
        horizontal="right"
        variant={snackbarVariant}
      />
    </>
  );
}
export default TenantDetails;

const ModalContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.lightest,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "35px",
  maxHeight: "90%",
  overflow: "auto",
}));
