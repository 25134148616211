import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { Typography } from "@mui/material";
import { useContext } from "react";
import { TabContext } from "./CustomTabs";
import { dontForwardTheseProps } from "../../functions/dontForwardTheseProps";

const IconContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "48px",
});

const TabNameContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const ChipContainer = styled(Box)({
  marginLeft: "auto",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  marginRight: "16px",
  paddingTop: "8px",
});

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => dontForwardTheseProps(["activeIndex", "index"], prop),
})(({ theme, activeIndex, index }) => ({
  backgroundColor: activeIndex === index ? theme.palette.primary.lightest : theme.palette.primary.darker,
  color: activeIndex === index ? theme.palette.primary.darker : theme.palette.primary.lightest,
}));

function TicketGridTabIndicatorContent(props) {
  const { icon, tabName, index, ticketCount } = props;
  const { activeIndex } = useContext(TabContext);
  return (
    <>
      <IconContainer>{icon}</IconContainer>
      <TabNameContainer>
        <Typography variant="body1">{tabName}</Typography>
      </TabNameContainer>
      <ChipContainer>
        <StyledChip variant="filled" label={ticketCount} size="small" activeIndex={activeIndex} index={index} />
      </ChipContainer>
    </>
  );
}

export default TicketGridTabIndicatorContent;
