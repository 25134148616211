import React, { createContext, useState, useEffect } from "react";
import { setCookie, getCookie, clearCookie } from "./CookieHelper";

const UserContext = createContext();

const USER_COOKIE = "currHelpdeskUser";
export function UserContextProvider({ children }) {
  const [user, setUser] = useState({});

  // Note : this func only checks if the user object is empty or not
  // actual jwt token can expire while user object is still set.
  const isUserLoggedIn = () => {
    if (Object.keys(user).length > 0) {
      return true;
    }
    return false;
  };

  const addUserToContext = (username) => {
    setUser({ user: username });
  };

  // saveUser only saves the user email in a cookie and context, not the entire token.
  const saveUser = (token) => {
    const { email } = getTokenComponents(token);
    setCookie(USER_COOKIE, email);
    addUserToContext(email); // sets loggedin user's email in the global context.
  };

  const getUserFromCookie = () => {
    const userCookie = getCookie(USER_COOKIE);
    return userCookie;
  };

  const clearUser = () => {
    clearCookie(USER_COOKIE);
    setUser({});
  };
   const [envVariables, setEnvVariables] = useState(null);

  const getTokenComponents = (token) => {
    if (!token) {
      return null;
    }
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join(""),
    );

    return JSON.parse(jsonPayload);
  };

  useEffect(() => {
    const userFromCookie = getUserFromCookie();
    if (userFromCookie) {
      addUserToContext(userFromCookie);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        isUserLoggedIn,
        saveUser,
        clearUser,
        envVariables,
        setEnvVariables,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;
