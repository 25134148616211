import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";

const keyCellStyle = { flex: "1", paddingLeft: "0px" };
const valueCellStyle = { flex: "1", fontWeight: "bold" };

function DetailsGrid(props) {
  const { map, item } = props;
  return (
    <Box sx={{ margin: "0px 25px 0px 50px" }}>
      <Table>
        <TableBody>
          <TableRow sx={{ display: "flex" }}>
            <TableCell sx={keyCellStyle}>{item}</TableCell>
            <TableCell sx={valueCellStyle}>{map[item]}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}

export default DetailsGrid;
