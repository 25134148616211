import React, { useEffect, useState, useContext } from "react";
import { Modal, Box, Typography, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useTranslation } from "react-i18next";
import HttpContext from "../../../services/HttpContext";
import UserContext from "../../../services/UserContext";
import DetailsActionButton from "../../commons/DetailsActionButton/DetailsActionButton";
import Snackbar from "../../commons/Snackbar";
import Constants from "../../../Constants";
import SentenceSkeleton from "../../commons/Skeleton";
import VerificationStatusContainer from "../../commons/VerificationStatusContainer";
import constants from "../../../Constants";

const ModalContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.lightest,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "35px",
}));

function VerificationButton({
  verificationType,
  existingVerifications,
  applicationData,
  refetchVerifications,
  readOnly,
  ticketId,
  personId = "0",
}) {
  const { REACT_APP_API_HELPDESK } = constants;
  const { t } = useTranslation();
  const { PostRequest } = useContext(HttpContext);
  // This is the email address of current Admin user
  const {
    user: { user },
  } = useContext(UserContext); // Directly destructuring the email address string

  const matchingVerifications = existingVerifications?.approvals?.filter(
    (item) => item.personId === personId && item.verificationType === verificationType,
  )[0];
  const existingVerificationState = matchingVerifications?.verificationStatus;

  const { VERIFIED, REJECTED } = Constants;
  const showVerificationStatus = [VERIFIED, REJECTED].includes(existingVerificationState);
  const [buttonState, setButtonState] = useState(""); // Intermediate state, set when clicking verify or reject button
  const [verificationSubmitError, setVerificationSubmitError] = useState();
  const [modalData, setModalData] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarTempMsg, setSnackbarTempMsg] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("");
  const [verificationComment, setVerificationComment] = useState("");
  const [verificationCommentError, setVerificationCommentError] = useState("");
  const [loadingConfirmButton, setLoadingConfirmButton] = useState(false);

  const handleClose = () => {
    setLoadingConfirmButton(false);
    setModalShow(false);
    setVerificationComment("");
  };

  const handleSnackBarClose = (event, reason) => {
    // If the user clicks anywhere, then the snackbar should'nt close
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleShow = (header, body) => {
    setModalData({ header, body });
    setModalShow(true);
  };

  const handleRejectShow = () => {
    setButtonState(Constants.REJECTED);
    handleShow(
      t(`APPLICATION:COMMENT_TYPES.${verificationType}`),
      t("APPLICATION:REJECT_MESSAGE", {
        checkName: t(`APPLICATION:COMMENT_TYPES.${verificationType}`),
      }),
    );
    setSnackbarTempMsg("reject");
  };

  const handleVerifyShow = () => {
    setButtonState(Constants.VERIFIED);
    handleShow(
      t(`APPLICATION:COMMENT_TYPES.${verificationType}`),
      t("APPLICATION:VERIFY_MESSAGE", {
        checkName: t(`APPLICATION:COMMENT_TYPES.${verificationType}`),
      }),
    );
    setSnackbarTempMsg("verify");
  };

  const handleConfirm = async () => {
    setLoadingConfirmButton(true);
    if (verificationComment.trim() === "") {
      setVerificationCommentError("Please enter a comment");
      return;
    } else {
      setVerificationCommentError("");
    }

    // API call to helpdesk-http-broker
    const verificationRequest = {
      applicationId: applicationData?.applicationId,
      assignedTo: user,
      approval: {
        verificationType,
        verificationStatus: buttonState,
        comment: verificationComment,
        personId,
      },
    };
    try {
      await PostRequest(`${REACT_APP_API_HELPDESK}/${ticketId}/verifications`, verificationRequest);
      setSnackbarMsg(
        snackbarTempMsg === "verify" ? t("COMMON:VERIFIED_SUCCESSFULLY") : t("COMMON:REJECTED_SUCCESSFULLY"),
      );
      setSnackbarOpen(true);
      setSnackbarVariant("success");
      setButtonState(null);
      handleClose();
      setVerificationSubmitError(null);
      refetchVerifications();
    } catch (e) {
      setSnackbarMsg(snackbarTempMsg === "verify" ? t("COMMON:VERIFICATION_FAILED") : t("COMMON:REJECTION_FAILED"));
      setSnackbarVariant("failure");
      setSnackbarOpen(true);
      handleClose();
    }
  };

  useEffect(() => {
    setDisabled(
      existingVerificationState?.length &&
        (existingVerificationState === Constants.REJECTED || existingVerificationState === Constants.VERIFIED),
    );
  }, [existingVerifications]);

  const showVerificationsSkeleton = !applicationData?.applicationId;

  const handleVerificationCommentChange = (e) => {
    setVerificationComment(e.target.value);
  };

  return (
    verificationType &&
    applicationData && (
      <>
        <Modal open={modalShow} onClose={handleClose}>
          <ModalContentContainer>
            <Box sx={{ display: "flex" }}>
              <Typography variant="h3">{modalData?.header}</Typography>
              <CloseIcon sx={{ fontSize: "25px", marginLeft: "auto" }} onClick={handleClose} />
            </Box>
            <Box sx={{ paddingY: "35px" }}>
              <Typography variant="body1">{modalData?.body}</Typography>
            </Box>
            <Box>
              <textarea
                value={verificationComment}
                onChange={handleVerificationCommentChange}
                style={{ width: "100%" }}
              />
            </Box>
            {verificationCommentError.trim() !== "" ? (
              <Box mt="5px">
                <Typography variant="body1">{verificationCommentError}</Typography>
              </Box>
            ) : null}

            <Box sx={{ display: "flex", marginTop: "25px" }}>
              <DetailsActionButton variant="outlined" size="small" onClick={handleClose}>
                {t("COMMON:CANCEL")}
              </DetailsActionButton>
              <Box sx={{ paddingLeft: "1em" }}>
                <DetailsActionButton
                  variant="outlined"
                  size="small"
                  onClick={handleConfirm}
                  loading={loadingConfirmButton}
                  disabled={verificationComment.trim() === ""}
                >
                  {t("COMMON:CONFIRM")}
                </DetailsActionButton>
              </Box>
            </Box>
          </ModalContentContainer>
        </Modal>
        {showVerificationStatus ? (
          <div style={{ width: "140px", display: "flex" }}>
            <VerificationStatusContainer>{t(`APPLICATION:${existingVerificationState}`)}</VerificationStatusContainer>
          </div>
        ) : (
          <SentenceSkeleton show={showVerificationsSkeleton}>
            <div style={{ width: "140px", display: "flex" }}>
              <DetailsActionButton
                variant="outlined"
                size="small"
                disabled={disabled || readOnly}
                onClick={handleRejectShow}
              >
                {t("COMMON:REJECT")}
              </DetailsActionButton>
              <DetailsActionButton
                variant="outlined"
                size="small"
                disabled={disabled || readOnly}
                onClick={handleVerifyShow}
                sx={{ marginLeft: "auto" }}
              >
                {t("COMMON:VERIFY")}
              </DetailsActionButton>
            </div>
          </SentenceSkeleton>
        )}

        {verificationSubmitError && (
          <span>
            <Typography variant="body1">{verificationSubmitError}</Typography>
          </span>
        )}
        <Snackbar
          open={snackbarOpen}
          handleSnackBarClose={handleSnackBarClose}
          msg={snackbarMsg}
          vertical="top"
          horizontal="right"
          variant={snackbarVariant}
        />
      </>
    )
  );
}

export default VerificationButton;
