export function setCookie(key, value) {
  document.cookie = `${key}=${value}; path=/;`;
}

export function getCookie(key) {
  const cookieName = `${key}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookiePairs = decodedCookie.split(";");

  for (let i = 0; i < cookiePairs.length; i += 1) {
    const cookie = cookiePairs[i].trim(); // returns key=value pair

    if (cookie.startsWith(cookieName)) {
      return cookie.substring(cookieName.length, cookie.length); // return value
    }
  }

  return "";
}

export function clearCookie(key) {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
