import React from "react";
import { getPaus } from "./utils";
import { useQuery } from "@tanstack/react-query";
import { Stack, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";

const PauEmails = ({ tenantIdentifier }) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["tenant_Id", tenantIdentifier],
    queryFn: () => getPaus(tenantIdentifier),
    enabled: !!tenantIdentifier,
  });
   const { t } = useTranslation();
  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : isSuccess ? (
        <Stack
          direction={"row"}
          sx={{
            width: "31.25rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <pre>
            <Typography sx={{ fontWeight: "400", fontSize: "0.875rem" }}>{t("COMMON:AUTHORISED_USER1")}:</Typography>
            <Typography sx={{ fontWeight: "600", fontSize: "0.875rem" }}>
              {data.pauEmail1 ? data.pauEmail1 : "Not Available"}
            </Typography>
          </pre>
          <pre>
            <Typography sx={{ fontWeight: "400", fontSize: "0.875rem" }}>{t("COMMON:AUTHORISED_USER2")}:</Typography>
            <Typography sx={{ fontWeight: "600", fontSize: "0.875rem" }}>
              {data.pauEmail2 ? data.pauEmail2 : "Not Available"}
            </Typography>
          </pre>
        </Stack>
      ) : isError ? (
        <Typography sx={{ color: "B90000" }}>{t("COMMON:UNABLE_FETCH_PAU_DETAILS")}</Typography>
      ) : null}
    </>
  );
};
export default PauEmails;