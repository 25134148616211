// This function is to suppress the below warning https://stackoverflow.com/questions/54468535/how-to-solve-warning-react-does-not-recognize-the-x-prop-on-a-dom-element

// This situation will arise when you send Custom props to emotion styled components because you want the style to change according to your JS variable

// You have to send the props you do not want to forward to the DOM as an array 'props' to this function
// The boolean return value of this function will tell MUI whether to forward the prop or not

/**
 *
 * @param {[prop1,prop2]} props
 * @param {prop} prop
 * @returns {boolean}
 */
export const dontForwardTheseProps = (props, prop) => !props.includes(prop);
