import React from "react";
import { useTranslation } from "react-i18next";

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <div style={{ fontSize: "26px" }}>{t("ERRORS:REQUEST_FORBIDDEN")}</div>
    </div>
  );
}
