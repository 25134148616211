import Constants from "../Constants";

const useFindProgressForTab = (verifications, applicationData) => {
  let businessDetailsProgress = false;
  let businessRepresentativesProgress = false;
  let businessOwnersProgress = false;
  let businessExecutivesProgress = false;
  let bankAccountDetailsProgress = false;

  /* -------------------------------------------------------------------------- */
  /*                          Business Details Progress                         */
  /* -------------------------------------------------------------------------- */

  if (verifications?.approvals) {
    const businessDocVerification = verifications?.approvals.find(
      (record) => record.verificationType === Constants.CommentTypes.BUSINESS_DOCUMENT,
    );
    const legalDocVerification = verifications?.approvals.find(
      (record) => record.verificationType === Constants.CommentTypes.LEGAL_BUSINESS_NAME_CHECK,
    );
    const countryRiskCheckVerification = verifications?.approvals.find(
      (record) => record.verificationType === Constants.CommentTypes.COUNTRY_RISK_CHECK,
    );
    businessDetailsProgress =
      legalDocVerification?.verificationStatus === Constants.VERIFIED ||
      legalDocVerification?.verificationStatus === Constants.REJECTED;
  }

  /* -------------------------------------------------------------------------- */
  /*                      Business Representatives Progress                     */
  /* -------------------------------------------------------------------------- */

  // const authorisedPerson = applicationData?.data?.partnerData?.authorisedPerson;
  const individualNameCheckApprovals = verifications?.approvals?.filter(
    (approval) => approval?.verificationType === "INDIVIDUAL_NAME_CHECK",
  );

  const verifiedCount =
    individualNameCheckApprovals?.filter((approval) => approval.verificationStatus === "VERIFIED")?.length || 0;

  businessRepresentativesProgress = verifiedCount === 2;

  // if (verifications?.approvals && authorisedPerson) {
  //   const personNotVerified = authorisedPerson.filter((map) => {
  //     const businessDocVerification = verifications?.approvals.find(
  //       (record) =>
  //         record?.personId === map?.personId &&
  //         record?.verificationType === Constants.CommentTypes.IDENTITY_DOCUMENT &&
  //         (record?.verificationStatus === Constants.VERIFIED || record?.verificationStatus === Constants.REJECTED),
  //     );
  //     return businessDocVerification == null;
  //   });
  //   const emailNotVerified = authorisedPerson.filter((map) => {
  //     const businessDocVerification = verifications?.approvals.find(
  //       (record) =>
  //         record?.personId === map?.personId &&
  //         record?.verificationType === Constants.CommentTypes.OFFICIAL_EMAIL_CHECK &&
  //         (record?.verificationStatus === Constants.VERIFIED || record?.verificationStatus === Constants.REJECTED),
  //     );
  //     return businessDocVerification == null;
  //   });

  //   businessRepresentativesProgress = personNotVerified?.length === 0 && emailNotVerified?.length === 0;
  // }

  /* -------------------------------------------------------------------------- */
  /*         Business Owners and Executives Progress Helper                     */
  /* -------------------------------------------------------------------------- */

  let ownersCount = 0;
  let executivesCount = 0;
  const businessRelations = applicationData?.data?.partnerData?.businessRelation;
  if (businessRelations) {
    businessRelations?.forEach((businessRelation) => {
      if (businessRelation.relationType === "OWNER") {
        ownersCount += 1;
      }
      if (businessRelation.relationType === "MANAGEMENT") {
        executivesCount += 1;
      }
    });
  }

  /* -------------------------------------------------------------------------- */
  /*                    Business Owners & Executives Progress                   */
  /* -------------------------------------------------------------------------- */

  if (verifications?.approvals && businessRelations) {
    let ownersVerifiedCount = 0;
    let executivesVerifiedCount = 0;

    businessRelations.some((map) => {
      if (map.relationType === "OWNER") {
        const ownersBusinessDocVerification = verifications?.approvals.find(
          (record) =>
            record?.personId === map?.personId &&
            (record?.verificationStatus === Constants.VERIFIED || record?.verificationStatus === Constants.REJECTED),
        );
        if (!ownersBusinessDocVerification) return true;
        if (ownersBusinessDocVerification) ownersVerifiedCount += 1;
      }
      return false;
    });

    businessRelations.some((map) => {
      if (map.relationType === "MANAGEMENT") {
        const executivesBusinessDocVerification = verifications?.approvals.find(
          (record) =>
            record?.personId === map?.personId &&
            (record?.verificationStatus === Constants.VERIFIED || record?.verificationStatus === Constants.REJECTED),
        );
        if (!executivesBusinessDocVerification) return true;
        if (executivesBusinessDocVerification) executivesVerifiedCount += 1;
      }
      return false;
    });
    businessOwnersProgress = ownersVerifiedCount === ownersCount;
    businessExecutivesProgress = executivesVerifiedCount === executivesCount;
  }

  /* -------------------------------------------------------------------------- */
  /*                          Bank Account Details Progress                     */
  /* -------------------------------------------------------------------------- */

  if (verifications?.approvals) {
    const bankAccountDetailsprog = verifications?.approvals.find(
      (record) => record.verificationType === Constants.CommentTypes.BANK_ACCOUNT_DETAILS,
    );
    bankAccountDetailsProgress = bankAccountDetailsprog;
  }

  /* -------------------------------------------------------------------------- */

  return {
    [Constants.APPLICATION_TABS.BUSINESS_DETAILS]: businessDetailsProgress,
    [Constants.APPLICATION_TABS.BUSINESS_REPRESENTATIVES]: businessRepresentativesProgress,
    [Constants.APPLICATION_TABS.BUSINESS_OWNERS]: businessOwnersProgress,
    [Constants.APPLICATION_TABS.BUSINESS_EXECUTIVES]: businessExecutivesProgress,
    [Constants.APPLICATION_TABS.BANK_ACCOUNT_DETAILS]: bankAccountDetailsProgress,
  };
};

export default useFindProgressForTab;
