import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ backgroundColor, color }) => ({
  position: 'fixed',
  padding: '5px',
  textAlign: 'center',
  width: '300px',
  zIndex: '100',
  right: '0px',
  top: '0px',
  transform: 'rotate(45deg)',
  translate: '57px 75px',
  boxShadow: '5px 2px 10px 0px #919191',
  backgroundColor: backgroundColor || '#FF9D37',
  color: color || '#313131',
}));

export default StyledBox;