import Box from "@mui/material/Box";
import { styled } from "@mui/material";

const CustomTabIndicatorContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: theme.palette.primary.light,
}));

export default CustomTabIndicatorContainer;
