import { useRef, useEffect } from "react";

const useOutsideClick = (callback) => {
  const outsideClickRef = useRef();

  const handleClick = (event) => {
    if (outsideClickRef.current && !outsideClickRef.current.contains(event.target)) callback();
  };

  useEffect(() => {
    document.addEventListener("click", handleClick, true);

    return () => document.removeEventListener("click", handleClick, true);
  }, [outsideClickRef]);

  return outsideClickRef;
};

export default useOutsideClick;
