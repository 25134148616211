import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";

const outlinedInputStyle = {
  height: "40px",
  marginTop: "30px",
  marginBottom: "10px",
  marginLeft: "30px",
};

function SearchBox(props) {
  const { value, onChange, ...others } = props;
  return (
    <Box sx={{ backgroundColor: "primary.main" }}>
      <OutlinedInput
        sx={outlinedInputStyle}
        placeholder="Search"
        value={value}
        onChange={onChange}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon style={{ fontSize: "24px" }} />
          </InputAdornment>
        }
        inputProps={{
          "aria-label": "Search",
        }}
        {...others}
      />
    </Box>
  );
}

export default SearchBox;
