import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";
import ActivityLogsModal from "../../commons/ActivityLogsModal/ActivityLogsModal";
import ButtonLink from "../../commons/ButtonLink/ButtonLink";
import DetailsActionButton from "../../commons/DetailsActionButton/DetailsActionButton";
import DocumentLink from "../../commons/DocumentLink";
import { ReactComponent as BackIcon } from "../../../assets/back.svg";
import Constants from "../../../Constants";
import ApplicationStatusContainer from "../../commons/ApplicationStatusContainer";

const labelContainerStyle = {
  display: "flex",
  flexDirection: "column",
  padding: "20px 60px 28px 20px",
};

const parentContainerStyle = {
  backgroundColor: "primary.light",
};

const buttonsContainerStyle = {
  display: "flex",
  flexDirection: "row",
  paddingRight: "60px",
  paddingTop: "16px",
  alignItems: "center",
};

function ProductActionSection(props) {
  const {
    readOnly,
    overallVerified,
    handleRejectAppShow,
    handleApproveAppShow,
    lastActiveTicketGridTabIndex,
    approveDisabled,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ticketId, appId } = useParams();

  const [activityLogModal, setActivityLogModal] = useState(false);
  const showActivityLogModal = () => setActivityLogModal(true);
  const hideActivityLogModal = () => setActivityLogModal(false);

  const navigateToApproval = () => {
    const state = {
      lastActiveTicketGridTabIndex,
    };
    navigate(Constants.PRODUCT_SUBSCRIPTION_URL, { state });
  };

  const { VERIFIED, REJECTED, APPLICATION_APPROVAL_STATUS } = Constants;
  const showApplicationStatus = [VERIFIED, REJECTED].includes(overallVerified);

  return (
    <>
      <Box sx={parentContainerStyle}>
        <Box sx={buttonsContainerStyle}>
          <DocumentLink onClick={() => navigateToApproval()}>
            <Box display="flex" alignItems="center" marginLeft="20px">
              <SvgIcon>
                <BackIcon />
              </SvgIcon>
            </Box>
          </DocumentLink>
          <Box sx={{ marginLeft: "auto" }}>
            <ButtonLink variant="outlined" size="small" onClick={showActivityLogModal}>
              {t("COMMON:ACTIVITY_LOG")}
            </ButtonLink>
          </Box>
          {showApplicationStatus ? (
            <ApplicationStatusContainer>
              {t(
                `APPLICATION:${overallVerified === VERIFIED ? APPLICATION_APPROVAL_STATUS.APPROVED : overallVerified}`,
              )}
            </ApplicationStatusContainer>
          ) : (
            <>
              <Box sx={{ marginLeft: "10px" }}>
                <DetailsActionButton variant="outlined" size="small" onClick={handleRejectAppShow} disabled={readOnly}>
                  {t("APPLICATION:REJECT_APPLICATION")}
                </DetailsActionButton>
              </Box>

              <Box sx={{ marginLeft: "10px" }}>
                <DetailsActionButton
                  variant="outlined"
                  size="small"
                  onClick={handleApproveAppShow}
                  disabled={readOnly || approveDisabled}
                >
                  {t("APPLICATION:APPROVE_APPLICATION")}
                </DetailsActionButton>
              </Box>
            </>
          )}
        </Box>

        <Box sx={labelContainerStyle}>
          <Typography variant="body1" component="span" fontWeight="light">
            Tenant name
          </Typography>
          <Typography variant="h1" role="heading">
            SYNTHIX
          </Typography>
        </Box>
      </Box>
      <ActivityLogsModal show={activityLogModal} onHide={hideActivityLogModal} ticketId={ticketId} />
    </>
  );
}

export default ProductActionSection;
