import React, { useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../../assets/back.svg";
import { Box, SvgIcon, Typography, Grid, Button, Stack, CircularProgress } from "@mui/material";
import TicketDetailsLeftSection from "./TicketDetailsLeftSection";
import TicketDetailsRightSection from "./TicketDetailsRightSection";
import ButtonLink from "../../commons/ButtonLink/ButtonLink";
import { useQuery } from "@tanstack/react-query";
import DocumentLink from "../../commons/DocumentLink";
import Constants from "../../../Constants";
import { getParticularTenantProviderDetails } from "../CreateTicketSection/utils";
import { formatDateToSystemDesignFormat } from "../../commons/Utils";
import { useTranslation } from "react-i18next";
import ActivityLogsModal from "../../commons/ActivityLogsModal/ActivityLogsModal";
import CloseTicketModal from "./CloseTicketModal";
import { getVerifications } from "../CreateTicketSection/utils";

const QueryTicketDetails = () => {
  const { ticketId, appId } = useParams();
  const location = useLocation();
  const [activityLogModal, setActivityLogModal] = useState(false);
  const showActivityLogModal = () => setActivityLogModal(true);
  const hideActivityLogModal = () => setActivityLogModal(false);
  const [openModal, setOpenModal] = useState(false);
  const openCloseTicketModal = () => setOpenModal(true);
  const closeModal = () => setOpenModal(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const activeIndex = location?.state?.lastActiveTicketGridTabIndex;
  const navigateToTicketGrid = () => {
    const state = {
      lastActiveTicketGridTabIndex: activeIndex,
    };
    navigate(Constants.QUERY_TICKETS_URL, { state });
  };

  const {
    data: data,
    isSuccess: isSuccess,
    isLoading: isLoading,
    isFetching: isFetching,
  } = useQuery({
    queryKey: ["Tenant_Provider_Details", appId],
    queryFn: () => getParticularTenantProviderDetails(ticketId, appId),
  });

  const { data: verificationData } = useQuery({
    queryKey: ["Verifications", ticketId],
    queryFn: () => getVerifications(ticketId),
  });
  const createdAtDate = formatDateToSystemDesignFormat(new Date(data?.createdAt));
  const status = [
    {
      status: "OPEN",
      text: t("COMMON:OPEN"),
    },
    {
      status: "IN_PROGRESS",
      text: t("COMMON:IN_PROGRESS"),
    },
    {
      status: "AWAITING_INPUT",
      text: t("COMMON:AWAITING_INPUT"),
    },
    {
      status: "RESOLVED",
      text: t("COMMON:RESOLVED"),
    },
    {
      status: "CLOSED",
      text: t("COMMON:CLOSED"),
    },
  ];

  const returnStatus = (currentStatus) => {
    const getStatus = status.find((givenstatus) => givenstatus.status === currentStatus);
    return getStatus ? getStatus.text : null;
  };
  const leftSectionDetails = [
    {
      title: t("COMMON:TICKET_ID"),
      value: ticketId,
    },
    {
      title: t("TICKETS:GRID_COLUMNS:CREATED_BY"),
      value: data?.createdBy,
    },
    {
      title: t("TICKETS:GRID_COLUMNS:CREATED_AT"),
      value: createdAtDate,
    },
    {
      title: t("TICKETS:GRID_COLUMNS:STATUS"),
      value: returnStatus(data?.status),
    },
  ];

  const comment = {
    title: t("COMMON:COMMENTS"),
    value: verificationData?.approvals?.[0]?.comment,
  };

  if (data?.status === "RESOLVED" || data?.status === "CLOSED") {
    leftSectionDetails.push(comment);
  }

  let customertype;
  if (data?.createdForEnum === "PREMIUM_CUSTOMER") {
    customertype = t("COMMON:PREMIUM_CUSTOMER");
  } else if (data?.createdForEnum === "THIRD_PARTY_PROVIDER") {
    customertype = t("COMMON:THIRD_PARTY_PROVIDER");
  }

  const rightSectionDetails = [
    { title: t("COMMON:SUBJECT"), value: data?.subject },
    {
      title: t("COMMON:DESCRIPTION"),
      value: data?.description,
    },
    {
      title: t("COMMON:EMAIL_ADDRESS_REPORTER"),
      value: data?.reporterEmail,
    },
  ];
  return (
    <div>
      <CloseTicketModal open={openModal} onClose={closeModal} />
      <Box
        sx={{
          backgroundColor: "primary.light",
          height: "10rem",
          paddingLeft: "1.875rem",
          position: "relative",
        }}
      >
        <DocumentLink onClick={() => navigateToTicketGrid()}>
          <SvgIcon sx={{ marginTop: "1.563rem" }}>
            <BackIcon />
          </SvgIcon>
        </DocumentLink>
        <Stack
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            right: "1rem",
            top: "1rem",
          }}
        >
          <ButtonLink variant="outlined" size="small" onClick={showActivityLogModal}>
            {t("COMMON:ACTIVITY_LOG")}
          </ButtonLink>
          {data?.status === "RESOLVED" ? null : (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#313131",
                color: "#ffffff",
                marginLeft: "25px",
                "&: hover": {
                  backgroundColor: "#313131",
                },
              }}
              disabled={location.state.readOnly}
              onClick={openCloseTicketModal}
            >
              {t("COMMON:CLOSE_TICKET")}
            </Button>
          )}
        </Stack>
        <Typography variant="h1" role="heading" sx={{ marginTop: "2.5rem", fontSize: "2.25rem", fontWeight: "400" }}>
          {t("COMMON:TICKET_DETAILS")}
        </Typography>
      </Box>
      {isLoading || isFetching ? (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "15%" }}>
          <CircularProgress size={50} />
        </Box>
      ) : isSuccess ? (
        <Box sx={{ backgroundColor: "#F8F9FA", height: "81vh" }}>
          <Grid container columnSpacing={7}>
            <Grid item md={6} xs={6}>
              <Box sx={{ margin: "1.5rem" }}>
                {leftSectionDetails.map((detail) => (
                  <TicketDetailsLeftSection label={detail.title} data={detail.value} />
                ))}
              </Box>
            </Grid>

            <Grid item md={6} xs={6}>
              <Box
                sx={{
                  backgroundColor: "#ffffff",
                  borderRadius: "1rem",
                  padding: "1rem",
                  margin: "1.5rem ",
                }}
              >
                <Stack sx={{ marginBottom: "1.5rem" }}>
                  <Typography sx={{ fontSize: "0.875rem", fontWeight: 400 }}>
                    {t("TICKETS:GRID_COLUMNS:CREATED_FOR")}
                  </Typography>
                  <Typography sx={{ fontSize: "0.875rem", fontWeight: 600 }}>{customertype}</Typography>
                </Stack>
                <Stack sx={{ marginBottom: "1.5rem" }}>
                  <Typography sx={{ fontSize: "0.875rem", fontWeight: 400 }}>
                    {data?.createdForEnum === "PREMIUM_CUSTOMER"
                      ? t("TICKETS:GRID_COLUMNS:CUSTOMER_DETAILS")
                      : t("TICKETS:GRID_COLUMNS:THIRD_PARTY_PROVIDER_DETAILS")}
                  </Typography>
                  <Typography sx={{ fontSize: "0.875rem", fontWeight: 600, display: "block" }}>
                    {data?.relevantName}
                  </Typography>

                  {data?.createdForEnum === "PREMIUM_CUSTOMER"
                    ? Object.keys(data?.helpdeskCompanyDetails).map(
                        (detail) =>
                          detail != "id" && (
                            <Typography sx={{ fontSize: "0.875rem", fontWeight: 600, display: "block" }}>
                              {data?.helpdeskCompanyDetails[detail]}
                            </Typography>
                          ),
                      )
                    : null}
                </Stack>
                {rightSectionDetails.map((detail) => (
                  <TicketDetailsRightSection label={detail.title} data={detail.value} />
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : null}
      <ActivityLogsModal show={activityLogModal} onHide={hideActivityLogModal} ticketId={ticketId} />
    </div>
  );
};
export default QueryTicketDetails;
