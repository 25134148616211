import React, { useState } from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as BackIcon } from "../../../assets/back.svg";
import { Box, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm, FormProvider } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import CreateTicketForm from "./CreateTicketForm";
import { createQueryTicket } from "./utils";
import Constants from "../../../Constants";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "../../commons/Snackbar";
import DocumentLink from "../../commons/DocumentLink";
import { getCookie } from "../../../services/CookieHelper";

const TicketsGrid = () => {
  const { t } = useTranslation();
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    shouldFocusError: true,
  });
  const [openSnackbar, setOpenSnackBar] = useState();
  const [snackBarMsg, setSnackBarMsg] = useState();
  const [snackBarVariant, setSnackBarVariant] = useState();
  const { handleSubmit, watch } = methods;
  const { data } = useQuery({
    queryFn: () => getTenentAndProvider(),
  });

  const handleSnackBarClose = () => {
    setOpenSnackBar(false);
  };
  const customer = data?.tenants?.find((item) => item.legalEntityName === watch("relevant_name"));
  let ticketID;

  const navigate = useNavigate();

  const navigateToTicketGrid = () => {
    navigate(Constants.QUERY_TICKETS_URL);
  };

  const handleSuccess = () => {
    setOpenSnackBar(true);
    setSnackBarMsg("Ticket Created Successfully");
    setSnackBarVariant("success");
  };
  const handleError = () => {
    setOpenSnackBar(true);
    setSnackBarMsg("Unable to create ticket");
    setSnackBarVariant("failure");
  };

  const { mutateAsync, isLoading } = useMutation(createQueryTicket, {
    onSuccess: handleSuccess,
    onError: handleError,
  });
  const navigatetocreatedscreen = (data) => {
    if (watch("customertype") === "PREMIUM_CUSTOMER") {
      data = { ...data, relevant_id: customer?.tenantIdentifier };
    } else {
      data = { ...data, relevant_id: "" };
    }
    data.currentHelpdeskUser = getCookie("currHelpdeskUser");
    const responseData = mutateAsync(data);
    responseData
      .then((data) => {
        ticketID = data.data.ticketId;
        navigate(Constants.QUERY_TICKETS_URL + `/ticket/${ticketID}`);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: "primary.light",
          height: "10rem",
          paddingLeft: "1.875rem",
        }}
      >
        <DocumentLink onClick={() => navigateToTicketGrid()}>
          <SvgIcon sx={{ marginTop: "1.563rem" }}>
            <BackIcon />
          </SvgIcon>
        </DocumentLink>
        <Typography variant="h1" role="heading" sx={{ marginTop: "2.5rem", fontSize: "2.25rem", fontWeight: "400" }}>
          {t("COMMON:CREATE_NEW_TICKET")}
        </Typography>
      </Box>
      <Box>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(navigatetocreatedscreen)}>
            <CreateTicketForm />
            <Stack
              direction="row"
              sx={{
                width: "37.5rem",
                marginTop: "2.5rem",
                marginLeft: "3.125rem",
              }}
            >
              <Button
                variant="contained"
                type="button"
                sx={{
                  backgroundColor: "#ffffff",
                  marginRight: "1.5rem",
                  border: "1px solid #ffffff",
                  "&: hover": {
                    backgroundColor: "#ffffff",
                  },
                }}
                onClick={navigateToTicketGrid}
              >
                {t("COMMON:CANCEL")}
              </Button>
              <LoadingButton
                type="Submit"
                variant="contained"
                sx={{
                  backgroundColor: "#313131",
                  color: "#ffffff",
                  "&: hover": {
                    backgroundColor: "#313131",
                  },
                }}
                loading={isLoading}
              >
                {t("COMMON:SUBMIT")}
              </LoadingButton>
            </Stack>
          </form>
        </FormProvider>
      </Box>
      <Snackbar
        open={openSnackbar}
        msg={snackBarMsg}
        variant={snackBarVariant}
        vertical="top"
        horizontal="right"
        handleSnackBarClose={handleSnackBarClose}
      />
    </>
  );
};
export default TicketsGrid;