import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import { Typography, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";

import HttpContext from "../../services/HttpContext";
import BasicAlert from "../commons/Toast/BasicAlert";
import { useScrollToTop } from "../../hooks/useScrollTopTop";
import DetailsActionButton from "../commons/DetailsActionButton/DetailsActionButton";
import CustomTabs from "../Tabs/CustomTabs";
import CustomTabPanel from "../Tabs/CustomTabPanel";
import Snackbar from "../commons/Snackbar";
import constants from "../../Constants";

import ProductBusinessDetails from "./DetailSection/ProductBusinessDetails";
import ProductActionSection from "./ActionSection/ProductActionSection";

import Constants, { snackbarMsgMapSuccess, snackbarMsgMapFailure } from "../../Constants";

function ProductSubscriptionDetails() {
  useScrollToTop();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { PostRequest, GetRequest } = useContext(HttpContext);
  const [activeIndex, setActiveIndex] = useState(0);
  const { ticketId, appId } = useParams();
  const { REACT_APP_API_HELPDESK } = constants;
  const lastActiveTicketGridTabIndex = location?.state?.lastActiveTicketGridTabIndex || 0;

  const [applicationData, setApplicationData] = useState(null);
  const [verifications, setVerifications] = useState();
  const [overallVerified, setOverallVerified] = useState("");

  const [show, setShow] = useState(false);
  const [basicAlertShow, setBasicAlertShow] = useState(false);

  const [applicationApprovalButtonState, setApplicationApprovalButtonState] = useState("");
  const [readOnly, setReadOnly] = useState(location?.state?.readOnly || applicationApprovalButtonState?.length > 0);

  // Will be active once all checkboxes are selected
  const [approveDisabled, setApproveDisabled] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarTempMsg, setSnackbarTempMsg] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSnackBarClose = (event, reason) => {
    // If the user clicks anywhere, then the snackbar shouldn't close
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await GetRequest(`${REACT_APP_API_HELPDESK}/${ticketId}/${appId}`, null, true);
      const responseJson = await response.json();
      setReadOnly(response.headers.get("readonly") === "true" || readOnly);
      setApplicationData({
        data: responseJson,
        applicationId: parseInt(appId, 10),
      });
    } catch (err) {
      console.log(err);
      setErrorMessage(err.toString());
      setBasicAlertShow(true);
    } finally {
      setLoading(false);
    }
  };

  const handleBasicAlertOk = () => navigate(constants.DASHBOARD_URL);

  const loadVerifications = async () => {
    try {
      const response = await GetRequest(`${REACT_APP_API_HELPDESK}/${ticketId}/verifications`);
      const overallVerification = response?.approvals?.find((approval) => approval.verificationType === "OVERALL");
      const overallStatus = overallVerification ? overallVerification.verificationStatus : null;

      setOverallVerified(overallStatus);
      setVerifications(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!applicationData) {
      loadData();
    }
  }, [applicationData]);

  useEffect(() => {
    if (!verifications) {
      loadVerifications();
      // if overall verification value exists, disable application level verification buttons
      const matchingVerifications = verifications?.approvals?.filter(
        (item) => item.verificationStatus === Constants.CommentTypes.OVERALL,
      )[0];
      const overallVerificationState = matchingVerifications?.verificationStatus;
      if (overallVerificationState?.length) {
        setReadOnly(true);
      }
    }
  }, [verifications]);

  const handleClose = () => {
    setShow(false);
    setARComment("");
    setApplicationApprovalButtonState("");
  };

  const [loadingConfirmButton, setLoadingConfirmButton] = useState(false);

  const [applicationApprovalRejectionComment, setARComment] = React.useState("");

  const handleConfirm = async () => {
    setLoadingConfirmButton(true);
    try {
      const adminActionRequest = {
        action: applicationApprovalButtonState,
        comment: applicationApprovalRejectionComment,
      };

      await PostRequest(`${REACT_APP_API_HELPDESK}/${ticketId}/action`, adminActionRequest);
      setSnackbarOpen(true);
      setSnackbarMsg(snackbarMsgMapSuccess[snackbarTempMsg]);
      setSnackbarVariant("success");
      setReadOnly(true);
    } catch (e) {
      setSnackbarOpen(true);
      setSnackbarMsg(snackbarMsgMapFailure[snackbarTempMsg]);
      setSnackbarVariant("failure");
      setApplicationApprovalButtonState("");
    } finally {
      loadVerifications();
      setShow(false);
      setLoadingConfirmButton(false);
    }
  };
  const [modalData, setModalData] = useState(null);

  const handleShow = (header, body) => {
    setModalData({ header, body });
    setShow(true);
  };

  const handleRejectAppShow = () => {
    setApplicationApprovalButtonState(Constants.APPLICATION_APPROVAL_STATUS.REJECTED);
    handleShow(t("APPLICATION:REJECT_APPLICATION"), t("APPLICATION:REJECT_APPLICATION_MESSAGE"));
    setSnackbarTempMsg("reject");
  };
  const handleApproveAppShow = () => {
    setApplicationApprovalButtonState(Constants.APPLICATION_APPROVAL_STATUS.APPROVED);
    handleShow(t("APPLICATION:APPROVE_APPLICATION"), t("APPLICATION:APPROVE_APPLICATION_MESSAGE"));
    setSnackbarTempMsg("approve");
  };

  return (
    <>
      <BasicAlert
        handleOK={handleBasicAlertOk}
        setShow={setBasicAlertShow}
        show={basicAlertShow}
        message={errorMessage}
      />
      <Modal open={show} onClose={handleClose}>
        <ModalContentContainer>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography variant="h3">{modalData?.header}</Typography>
            <CloseIcon sx={{ fontSize: "25px", marginLeft: "auto" }} onClick={handleClose} />
          </Box>
          <Box>
            <Box sx={{ paddingTop: "35px" }}>
              <Typography variant="body1">{modalData?.body}</Typography>
            </Box>
            <Box sx={{ paddingTop: "25px" }}>
              <textarea
                style={{ width: "100%" }}
                value={applicationApprovalRejectionComment}
                onChange={(e) => setARComment(e.target.value)}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              paddingTop: "35px",
            }}
          >
            <DetailsActionButton variant="outlined" size="small" onClick={handleClose}>
              {t("COMMON:CANCEL")}
            </DetailsActionButton>
            <Box sx={{ marginLeft: "1em" }}>
              <DetailsActionButton
                variant="outlined"
                size="small"
                onClick={handleConfirm}
                loading={loadingConfirmButton}
                disabled={applicationApprovalRejectionComment.trim() === ""}
              >
                {t("COMMON:CONFIRM")}
              </DetailsActionButton>
            </Box>
          </Box>
        </ModalContentContainer>
      </Modal>
      <ProductActionSection
        readOnly={readOnly}
        overallVerified={overallVerified}
        applicationData={applicationData}
        handleRejectAppShow={handleRejectAppShow}
        handleApproveAppShow={handleApproveAppShow}
        applicationApprovalButtonState={applicationApprovalButtonState}
        lastActiveTicketGridTabIndex={lastActiveTicketGridTabIndex}
        approveDisabled={approveDisabled}
      />
      <Box>
        <CustomTabs activeIndex={activeIndex} setActiveIndex={setActiveIndex}>
          <CustomTabPanel index={0}>
            {loading ? (
              <></>
            ) : (
              <ProductBusinessDetails
                verifications={verifications}
                refetchVerifications={loadVerifications}
                ticketId={parseInt(ticketId, 10)}
                applicationData={applicationData}
                readOnly={readOnly}
                setApproveDisabled={setApproveDisabled}
              />
            )}
          </CustomTabPanel>
        </CustomTabs>
      </Box>
      <Snackbar
        open={snackbarOpen}
        handleSnackBarClose={handleSnackBarClose}
        msg={snackbarMsg}
        vertical="top"
        horizontal="right"
        variant={snackbarVariant}
      />
    </>
  );
}
export default ProductSubscriptionDetails;

const ModalContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.lightest,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "35px",
  maxHeight: "90%",
  overflow: "auto",
}));
