import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function VerificationStatusContainer(props) {
  const { children, ...rest } = props;
  return (
    <Box
      backgroundColor="primary.darker"
      borderRadius="5px"
      textAlign="center"
      padding="3px 9px"
      width="100%"
      {...rest}
    >
      <Typography variant="body1" sx={{ color: "primary.light" }}>
        {children}
      </Typography>
    </Box>
  );
}

export default VerificationStatusContainer;
