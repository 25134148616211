import { RadioGroup, TextField, FormControlLabel, Radio, Typography, MenuItem, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useFormContext, Controller, useFormState } from "react-hook-form";
import Grid from "@mui/material/Grid";
import { getTenentAndProvider } from "./utils";
import PauEmails from "./PauEmails";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CreateTicketForm = () => {
  const { control, watch, unregister } = useFormContext();
  const state = useFormState();
  const [tenentProvidersData, setTenantProvidersData] = useState();
  const { t } = useTranslation();
  const customerTypes = [
    { type: "PREMIUM_CUSTOMER", label: t("COMMON:PREMIUM_CUSTOMER") },
    { type: "THIRD_PARTY_PROVIDER", label: t("COMMON:THIRD_PARTY_PROVIDER") },
  ];
  const { data, isSuccess, isLoading, isError } = useQuery({
    queryFn: () => getTenentAndProvider(),
    staleTime: 1000,
  });
  useEffect(() => {
    if (isSuccess && data) {
      setTenantProvidersData(data);
    }
  }, [data]);
  let customer;
  if (watch("relevant_name") && isSuccess) {
    customer = tenentProvidersData?.tenants?.find((customer) => customer.legalEntityName === watch("relevant_name"));
  }
  const radioButtonStyle = {
    "& .MuiSvgIcon-root": {
      fontSize: "1.5rem",
      color: "#313131",
      marginLeft: "0.313rem",
    },
    "& .Mui-checked": {
      color: "#313131",
    },
    "& .MuiFormControlLabel-label": {
      fontSize: "1rem",
      color: "#313131",
    },
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          paddingLeft: "3.125rem",
          paddingTop: "1.875rem",
        }}
      >
        <Grid item sm={12} md={12}>
          <Typography sx={{ fontSize: "1rem", fontWeight: "600", color: "#313131" }}>
            {t("COMMON:TICKET_CREATED_FOR")}:
          </Typography>
          <Controller
            name="customertype"
            control={control}
            rules={{ required: t("COMMON:SELECT_CUSTOMER_TYPE") }}
            render={({ field }) => (
              <RadioGroup
                row
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  if (watch("customertype") === "PREMIUM_CUSTOMER") {
                    unregister("THIRD_PARTY_PROVIDER");
                  } else if (watch("customertype") === "THIRD_PARTY_PROVIDER") {
                    unregister("customer");
                  }
                }}
              >
                {customerTypes.map((CustomerType) => (
                  <FormControlLabel
                    value={CustomerType.type}
                    control={<Radio sx={radioButtonStyle} />}
                    label={CustomerType.label}
                  />
                ))}
              </RadioGroup>
            )}
          />
          {state.errors.customertype ? (
            <Typography sx={{ fontSize: "0.75rem", color: "#B90000", marginLeft: "0.875rem" }}>
              {state.errors.customertype.message}
            </Typography>
          ) : null}
        </Grid>
        {watch("customertype") !== undefined ? (
          <Grid item sm={12} md={12}>
            <Controller
              name="relevant_name"
              control={control}
              rules={{ required: t("COMMON:FIELD_REQUIRED") }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  select
                  variant="outlined"
                  label={
                    watch("customertype") === "PREMIUM_CUSTOMER"
                      ? t("COMMON:SELECT_CUSTOMER")
                      : t("COMMON:SELECT_THIRD_PARTY_PROVIDER")
                  }
                  SelectProps={{
                    IconComponent: ExpandMoreIcon,
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 400,
                        },
                      },
                    },
                  }}
                  sx={{
                    width: "37.5rem",
                  }}
                  error={!!fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : null}
                >
                  {watch("customertype") === "PREMIUM_CUSTOMER" && isLoading ? (
                    <CircularProgress />
                  ) : watch("customertype") === "PREMIUM_CUSTOMER" && isSuccess ? (
                    tenentProvidersData?.tenants?.map((tenants) => (
                      <MenuItem key={tenants.tenantIdentifier} value={tenants.legalEntityName}>
                        <pre>
                          {tenants.legalEntityName}
                          {"\n"}
                          {tenants.tenantIdentifier}
                        </pre>
                      </MenuItem>
                    ))
                  ) : watch("customertype") === "THIRD_PARTY_PROVIDER" && isLoading ? (
                    <CircularProgress />
                  ) : watch("customertype") === "THIRD_PARTY_PROVIDER" && isSuccess ? (
                    tenentProvidersData?.providers?.map((providers) => (
                      <MenuItem key={providers.providerCode} value={providers.providerName}>
                        {providers.providerName}
                      </MenuItem>
                    ))
                  ) : isError ? (
                    <Typography sx={{ color: "#B90000" }}>{t("COMMON:UNABLE_FETCH_TENANTS_PROVIDERS")}</Typography>
                  ) : null}
                </TextField>
              )}
            />
          </Grid>
        ) : null}
        {watch("customertype") === "PREMIUM_CUSTOMER" && watch("relevant_name") && customer ? (
          <Grid item sm={12} md={12}>
            <PauEmails tenantIdentifier={customer?.tenantIdentifier} />
          </Grid>
        ) : null}
        <Grid item sm={12} md={12}>
          <Controller
            name="subject"
            control={control}
            rules={{ required: t("COMMON:FIELD_REQUIRED") }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                variant="outlined"
                label={t("COMMON:SUBJECT")}
                sx={{
                  width: "37.5rem",
                }}
                inputProps={{ maxLength: 256 }}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item sm={12} md={12}>
          <Controller
            name="description"
            control={control}
            rules={{
              required: t("COMMON:FIELD_REQUIRED"),
              maxLength: { value: 2048, message: t("COMMON:MAX_2048") },
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                multiline
                label={t("COMMON:DESCRIPTION")}
                rows={3}
                sx={{
                  width: "37.5rem",
                }}
                inputProps={{ maxLength: 2048 }}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item sm={12} md={12}>
          <Controller
            name="reporter_email"
            control={control}
            rules={{
              required: t("COMMON:FIELD_REQUIRED"),
              pattern: {
                value: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,}$/,
                message: t("COMMON:INVALID_EMAIL"),
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                label={t("COMMON:EMAIL_ADDRESS_REPORTER")}
                sx={{
                  width: "37.5rem",
                }}
                inputProps={{ maxLength: 256 }}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default CreateTicketForm;