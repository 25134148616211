import axios from "axios";

const apiClient = axios.create({
  baseURL: "/core",
  withCredentials: true,
  timeout: 10000,
});

export const getTenentAndProviderDetails = () => {
  return apiClient.get(`/reference/premium-tenants-and-providers`);
};

export const getTenentAndProvider = async () => {
  const response = await getTenentAndProviderDetails();
  return response.data;
};

export const getPauDetails = (tenant_id) => {
  return apiClient.get(`/reference/pau-emails?tenant_id=${tenant_id}`);
};

export const getPaus = async (tenant_id) => {
  const response = await getPauDetails(tenant_id);
  return response.data;
};

export const createQueryTicket = (ticketDetails) =>
  apiClient.post("/query/ticket", {
    created_for_enum: ticketDetails.customertype,
    ticket_sub_type: "HELPDESK_USER_QUERY",
    subject: ticketDetails.subject.trim(),
    description: ticketDetails.description.trim(),
    relevant_name: ticketDetails.relevant_name,
    relevant_id: ticketDetails.relevant_id,
    reporter_email: ticketDetails.reporter_email,
    created_by_email: ticketDetails.currentHelpdeskUser,
  });

export const getParticularTenantProviderDetail = (ticketId, appId) => {
  return apiClient.get(`/helpdesk/${ticketId}/${appId}`);
};

export const getParticularTenantProviderDetails = async (ticketId, appId) => {
  const response = await getParticularTenantProviderDetail(ticketId, appId);
  return response.data;
};

export const closeTicket = ({ticketId, comment}) => 
  apiClient.post(`/helpdesk/${ticketId}/action`, {
    action: "CLOSED",
    comment: comment,
  });

export const getVerificationDetails = (ticketId) => {
  return apiClient.get(`/helpdesk/${ticketId}/verifications`);
};

export const getVerifications = async (ticketId) => {
  const response = await getVerificationDetails(ticketId);
  return response.data;
};

