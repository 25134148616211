import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Grid from "@mui/material/Grid";
import Constants from "../../../Constants";
import VerificationButtons from "../ActionSection/VerificationButton";
import DetailsGrid from "../../commons/DetailsGrid/DetailsGrid";
import ApplicationDetailsPaper from "../../commons/ApplicationDetailsPaper/ApplicationDetailsPaper";
import VerificationIconContainer from "../../commons/VerificationIconContainer/VerificationIconContainer";
import DocumentLink from "../../commons/DocumentLink";
import AppDetailsContentContainer from "../../commons/AppDetailsContentContainer";
import SentenceSkeleton from "../../commons/Skeleton";
import DetailsTitleContainer from "../../commons/DetailsTitleContainer";
import VerificationItemContainer from "../../commons/VerificationItemContainer/VerificationItemContainer";
import { addToDetailMap } from "../../commons/Utils";

function BusinessDetails({ verifications, ticketId, applicationData, readOnly, refetchVerifications }) {
  const { t } = useTranslation();
  const tableMap = useMemo(() => {
    const tableDetailsMap = new Map([]);

    addToDetailMap(applicationData?.data?.location, "Location", tableDetailsMap);
    addToDetailMap(applicationData?.data?.phone, "Phone", tableDetailsMap);
    addToDetailMap(applicationData?.data?.email, "Email", tableDetailsMap);
    addToDetailMap(applicationData?.data?.legalName, "Legal business name", tableDetailsMap);
    addToDetailMap(applicationData?.data?.teamName, "Team name", tableDetailsMap);
    addToDetailMap(applicationData?.data?.type, "Type", tableDetailsMap);
    addToDetailMap(applicationData?.data?.sector, "Sector", tableDetailsMap);
    addToDetailMap(applicationData?.data?.identifier?.pan, "PAN", tableDetailsMap);
    addToDetailMap(applicationData?.data?.identifier?.gstin, "GSTIN", tableDetailsMap);
    addToDetailMap(applicationData?.data?.identifier?.llp, "LLP", tableDetailsMap);
    addToDetailMap(applicationData?.data?.identifier?.cin, "CIN", tableDetailsMap);

    if (applicationData?.data?.address?.line01)
      addToDetailMap(
        (applicationData?.data?.address?.line01 ?? "") +
          ", " +
          (applicationData?.data?.address?.line02 ?? "") +
          ", " +
          (applicationData?.data?.address?.city ?? "") +
          ", " +
          (applicationData?.data?.address?.zipcode ?? ""),
        "Address",
        tableDetailsMap,
      );

    addToDetailMap(applicationData?.data?.address?.website, "Website", tableDetailsMap);
    addToDetailMap(applicationData?.data?.description, "Description", tableDetailsMap);
    addToDetailMap(applicationData?.data?.pricingPlan, "Pricing Plan", tableDetailsMap);

    return tableDetailsMap;
  }, [applicationData]);

  return (
    <>
      <DetailsTitleContainer>{t("APPLICATION:BUSINESS_DETAILS")}</DetailsTitleContainer>
      <AppDetailsContentContainer>
        <Box sx={{ flex: 1 }}>
          {Object.keys(tableMap).map((item, idx) => (
            <DetailsGrid key={idx} map={tableMap} item={item} />
          ))}
        </Box>
        <Box sx={{ flex: 1 }}>
          <Box>
            <ApplicationDetailsPaper elevation={0}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <VerificationIconContainer>
                  <ErrorOutlineIcon sx={{ fontSize: "1.5em" }} />
                </VerificationIconContainer>
                <Typography variant="h3" component="span">
                  {t("APPLICATION:DOCUMENTS_TO_BE_VERIFIED")}
                </Typography>
              </Box>

              <Box marginTop="1.5em">
                <VerificationItemContainer>
                  <Grid item xs="auto">
                    <SentenceSkeleton show={false} width="1em">
                      <VerificationIconContainer>
                        <WebAssetIcon />
                      </VerificationIconContainer>
                    </SentenceSkeleton>
                  </Grid>
                  <Grid item xs="auto">
                    <SentenceSkeleton show={false} width="10em" marginX="2em">
                      <DocumentLink underline={false} disabled>
                        {t("APPLICATION:COMMENT_TYPES.LEGAL_BUSINESS_NAME_CHECK")}
                      </DocumentLink>
                    </SentenceSkeleton>
                  </Grid>
                  <Grid item xs="auto" marginLeft="auto" width="140px">
                    <SentenceSkeleton show={false} width="5em">
                      <VerificationButtons
                        verificationType={Constants.CommentTypes.LEGAL_BUSINESS_NAME_CHECK}
                        existingVerifications={verifications}
                        refetchVerifications={refetchVerifications}
                        applicationData={applicationData}
                        readOnly={readOnly}
                        ticketId={ticketId}
                      />
                    </SentenceSkeleton>
                  </Grid>
                </VerificationItemContainer>
              </Box>
            </ApplicationDetailsPaper>
          </Box>
        </Box>
      </AppDetailsContentContainer>
    </>
  );
}

export default BusinessDetails;
