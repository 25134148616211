import Link from "@mui/material/Link";
import { styled } from "@mui/material";

function DocumentLink(props) {
  const { children, underline = true, ...rest } = props;
  return (
    <StyledLink variant="body1" component="span" underline={underline ? "always" : "none"} {...rest}>
      {children}
    </StyledLink>
  );
}

const StyledLink = styled(Link)(({ theme }) => ({
  "&[disabled]": {
    pointerEvents: "none",
  },
  color: theme.palette.primary.darker,
  textDecorationColor: theme.palette.primary.darker,
  cursor: "pointer",
}));

export default DocumentLink;
