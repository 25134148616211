import { styled } from "@mui/material";
import Box from "@mui/material/Box";

const SpinnerContainer = styled(Box)({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

export default SpinnerContainer;
