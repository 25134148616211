import React, { useMemo } from "react";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTranslation } from "react-i18next";
import VerificationButtons from "../ActionSection/VerificationButton";
import DetailsGrid from "../../commons/DetailsGrid/DetailsGrid";
import ApplicationDetailsPaper from "../../commons/ApplicationDetailsPaper/ApplicationDetailsPaper";
import VerificationIconContainer from "../../commons/VerificationIconContainer/VerificationIconContainer";
import DocumentLink from "../../commons/DocumentLink";
import AppDetailsContentContainer from "../../commons/AppDetailsContentContainer";
import SentenceSkeleton from "../../commons/Skeleton";
import DetailsTitleContainer from "../../commons/DetailsTitleContainer";
import Constants from "../../../Constants";
import VerificationItemContainer from "../../commons/VerificationItemContainer/VerificationItemContainer";
import { addToDetailMap } from "../../commons/Utils";

function BusinessRepresentatives({ verifications, ticketId, applicationData, readOnly, refetchVerifications }) {
  const { t } = useTranslation();

  const tableMap1 = useMemo(() => {
    const tableDetailsMap = new Map([]);
    addToDetailMap(applicationData?.data?.pau1?.businessEmail, "Business email", tableDetailsMap);
    addToDetailMap(applicationData?.data?.pau1?.role, "Role", tableDetailsMap);
    addToDetailMap(applicationData?.data?.pau1?.firstName, "First name", tableDetailsMap);
    addToDetailMap(applicationData?.data?.pau1?.lastName, "Last name", tableDetailsMap);
    addToDetailMap(applicationData?.data?.pau1?.phone, "Phone", tableDetailsMap);
    return tableDetailsMap;
  }, [applicationData]);

  const tableMap2 = useMemo(() => {
    const tableDetailsMap = new Map([]);
    addToDetailMap(applicationData?.data?.pau2?.businessEmail, "Business email", tableDetailsMap);
    addToDetailMap(applicationData?.data?.pau2?.role, "Role", tableDetailsMap);
    addToDetailMap(applicationData?.data?.pau2?.firstName, "First name", tableDetailsMap);
    addToDetailMap(applicationData?.data?.pau2?.lastName, "Last name", tableDetailsMap);
    addToDetailMap(applicationData?.data?.pau2?.phone, "Phone", tableDetailsMap);
    return tableDetailsMap;
  }, [applicationData]);

  return (
    <>
      <DetailsTitleContainer>PAU 1</DetailsTitleContainer>
      <AppDetailsContentContainer>
        <Box sx={{ flex: 1 }}>
          {Object.keys(tableMap1).map((item, idx) => (
            <DetailsGrid key={idx} map={tableMap1} item={item} />
          ))}
        </Box>
        <Box sx={{ flex: 1 }}>
          <ApplicationDetailsPaper elevation={0}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <VerificationIconContainer>
                <ErrorOutlineIcon sx={{ fontSize: "1.5em" }} />
              </VerificationIconContainer>
              <Typography variant="h3" component="span">
                PAU verification
              </Typography>
            </Box>

            <Box marginTop="1.5em">
              <VerificationItemContainer>
                <Grid item xs="auto">
                  <SentenceSkeleton show={false} width="1em">
                    <VerificationIconContainer>
                      <WebAssetIcon />
                    </VerificationIconContainer>
                  </SentenceSkeleton>
                </Grid>
                <Grid item xs="auto">
                  <SentenceSkeleton show={false} width="10em" marginX="2em">
                    <DocumentLink variant="body1" underline={false} disabled>
                      Individual name check
                    </DocumentLink>
                  </SentenceSkeleton>
                </Grid>
                <Grid item xs="auto" marginLeft="auto" width="140px">
                  <SentenceSkeleton show={false} width="5em">
                    <VerificationButtons
                      verificationType={Constants.CommentTypes.INDIVIDUAL_NAME_CHECK}
                      existingVerifications={verifications}
                      refetchVerifications={refetchVerifications}
                      applicationData={applicationData}
                      readOnly={readOnly}
                      ticketId={ticketId}
                      personId="0"
                    />
                  </SentenceSkeleton>
                </Grid>
              </VerificationItemContainer>
            </Box>
          </ApplicationDetailsPaper>
        </Box>
      </AppDetailsContentContainer>

      <DetailsTitleContainer>PAU 2</DetailsTitleContainer>
      <AppDetailsContentContainer>
        <Box sx={{ flex: 1 }}>
          {Object.keys(tableMap2).map((item, idx) => (
            <DetailsGrid key={idx} map={tableMap2} item={item} />
          ))}
        </Box>
        <Box sx={{ flex: 1 }}>
          <ApplicationDetailsPaper elevation={0}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <VerificationIconContainer>
                <ErrorOutlineIcon sx={{ fontSize: "1.5em" }} />
              </VerificationIconContainer>
              <Typography variant="h3" component="span">
                PAU verification
              </Typography>
            </Box>

            <Box marginTop="1.5em">
              <VerificationItemContainer>
                <Grid item xs="auto">
                  <SentenceSkeleton show={false} width="1em">
                    <VerificationIconContainer>
                      <WebAssetIcon />
                    </VerificationIconContainer>
                  </SentenceSkeleton>
                </Grid>
                <Grid item xs="auto">
                  <SentenceSkeleton show={false} width="10em" marginX="2em">
                    <DocumentLink variant="body1" underline={false} disabled>
                      Individual name check
                    </DocumentLink>
                  </SentenceSkeleton>
                </Grid>
                <Grid item xs="auto" marginLeft="auto" width="140px">
                  <SentenceSkeleton show={false} width="5em">
                    <VerificationButtons
                      verificationType={Constants.CommentTypes.INDIVIDUAL_NAME_CHECK}
                      existingVerifications={verifications}
                      refetchVerifications={refetchVerifications}
                      applicationData={applicationData}
                      readOnly={readOnly}
                      ticketId={ticketId}
                      personId="1"
                    />
                  </SentenceSkeleton>
                </Grid>
              </VerificationItemContainer>
            </Box>
          </ApplicationDetailsPaper>
        </Box>
      </AppDetailsContentContainer>
    </>
  );
}

export default BusinessRepresentatives;
