import React, { useEffect, useContext } from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import HttpContext from "../../services/HttpContext";
import Constants from "../../Constants";
import constants from "../../Constants";

function UserManagementModal({
  show,
  onHide,
  hideUserManagementLogModal,
  userModalData,
  setUserModalData,
  handleSnackBarChange,
  fetchData,
  userModalType,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { PostRequest, GetRequest, PutRequest } = useContext(HttpContext);

  const [roleNames, setRoleNames] = React.useState([]);
  const [fieldsValid, setFieldsValid] = React.useState({
    isUsernameValid: false,
    isRolesAssignedValid: false,
  });
  const [fieldsMsg, setFieldsMsg] = React.useState({
    usernameErrMsg: "",
    rolesAssignedErrMsg: "",
  });
  const setState = (key, val) => {
    setFieldsValid((prevFieldsValid) => ({ ...prevFieldsValid, [key]: val }));
  };
  const setMsgState = (key, val) => {
    setFieldsMsg((prevFieldsMsg) => ({ ...prevFieldsMsg, [key]: val }));
  };

  const handleUsernameChange = (event) => {
    const val = event.target.value;
    setUserModalData({ userName: val, roles: userModalData?.roles });
    if (val !== "") {
      setState("isUsernameValid", false);
      setMsgState("usernameErrMsg", "");
    }
  };

  const handleRolesAssignedChange = (event) => {
    const val = event.target.value;
    setUserModalData({
      userName: userModalData?.userName,
      roles: typeof val === "string" ? val.split(",") : val,
    });
    if (val.length > 0) {
      setState("isRolesAssignedValid", false);
      setMsgState("rolesAssignedErrMsg", "");
    }
  };

  const handleConfirm = async () => {
    const createUser = {
      userName: userModalData?.userName,
      roles: userModalData?.roles,
    };
    try {
      if (userModalType === "edit") await PutRequest(constants.REACT_APP_EDIT_USER, createUser);
      else await PostRequest(constants.REACT_APP_CREATE_USER, createUser);
      handleSnackBarChange(
        true,
        userModalType === "edit"
          ? t("USER_MANAGEMENT:USER_EDIT_SUCCESS_MSG")
          : t("USER_MANAGEMENT:USER_ASSIGN_SUCCESS_MSG"),
        "success",
      );
      fetchData();
    } catch (e) {
      handleSnackBarChange(true, e.message, "failure");
    }
    hideUserManagementLogModal();
  };

  const saveUser = () => {
    const validEmail = userModalData?.userName.toLowerCase().match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/);
    const domain = userModalData?.userName?.split("@")[1];
    const synthixDomainCheck =
      domain?.split(".")[0] === Constants.SYNTHIX || domain?.split(".")[0] === Constants.BREAKINGWAVE;

    if (!validEmail) {
      setState("isUsernameValid", true);
      setMsgState("usernameErrMsg", t("USER_MANAGEMENT:ERRORS.USERNAME_NOT_VALID_EMAIL_ERROR"));
    } else if (!synthixDomainCheck) {
      setState("isUsernameValid", true);
      setMsgState("usernameErrMsg", t("USER_MANAGEMENT:ERRORS.USERNAME_NOT_SYNTHIX_OR_BW_DOMAIN_ERROR"));
    }
    if (userModalData?.roles.length === 0) {
      setState("isRolesAssignedValid", true);
      setMsgState("rolesAssignedErrMsg", t("USER_MANAGEMENT:ERRORS.ROLE_ERROR"));
    }
    if (validEmail && synthixDomainCheck && userModalData?.roles.length !== 0) {
      handleConfirm();
    }
    return null;
  };

  function getStyles(roleName, assignedRole) {
    return {
      fontWeight:
        assignedRole.indexOf(roleName) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
      backgroundColor:
        assignedRole.indexOf(roleName) === -1 ? theme.palette.primary.lighter : theme.palette.primary.light,
    };
  }

  useEffect(() => {
    const fetchRolesList = async () => {
      try {
        const response = await GetRequest(constants.REACT_APP_FETCH_ROLE);
        const tempArr = [];
        response.forEach((x) => tempArr.push(x.roleName));
        setRoleNames(tempArr);
      } catch (error) {
        console.error(error);
      }
    };
    fetchRolesList();
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const modalContentContainerStyle = {
    backgroundColor: "primary.lightest",
    width: "70vw",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const titleContainerStyle = {
    display: "flex",
    flexDirection: "row",
    padding: "35px 35px 0px 35px",
    marginBottom: "10px",
  };

  return (
    <Modal open={show} onClose={onHide}>
      <Box sx={modalContentContainerStyle}>
        <Box sx={titleContainerStyle}>
          <Typography variant="h1">
            {userModalType === "edit" ? t("USER_MANAGEMENT:EDIT_USER") : t("USER_MANAGEMENT:ASSIGN_USER")}
          </Typography>
          <CloseIcon sx={{ marginLeft: "auto", fontSize: "25px", cursor: "pointer" }} onClick={onHide} />
        </Box>
        <Box sx={{ padding: "35px" }}>
          <FormControl fullWidth sx={{ padding: "10px" }}>
            <TextField
              value={userModalData?.userName}
              id="outlined-basic"
              label={t("USER_MANAGEMENT:FORM_FIELD.USER_NAME")}
              onChange={handleUsernameChange}
              error={fieldsValid.isUsernameValid}
              helperText={fieldsMsg.usernameErrMsg}
              disabled={userModalType === "edit"}
            />
          </FormControl>
          <FormControl fullWidth sx={{ padding: "10px" }} error={fieldsValid.isRolesAssignedValid}>
            <InputLabel sx={{ marginLeft: "10px", marginTop: "10px" }}>
              {t("USER_MANAGEMENT:FORM_FIELD.ROLE")}
            </InputLabel>
            <Select
              multiple
              value={userModalData?.roles}
              onChange={handleRolesAssignedChange}
              input={<OutlinedInput label="Roles" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {roleNames.map((roleName) => (
                <MenuItem key={roleName} value={roleName} style={getStyles(roleName, userModalData?.roles)}>
                  {roleName}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{fieldsMsg.rolesAssignedErrMsg}</FormHelperText>
          </FormControl>
          <Box sx={{ padding: "10px" }}>
            <Button sx={{ backgroundColor: "primary.dark" }} onClick={saveUser}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default UserManagementModal;
