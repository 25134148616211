import React from "react";
import Skeleton from "@mui/material/Skeleton";

function SentenceSkeleton(props) {
  const { children, show, width, marginX } = props;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return show ? <Skeleton sx={{ height: "2em", width: width ?? "auto", marginX }} /> : <>{children}</>;
}

export default SentenceSkeleton;
