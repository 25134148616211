import React from "react";
import { Box, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Constants from "../../../Constants";
import { useTranslation } from "react-i18next";

const TicketCreatedScreen = () => {
  const { ticketId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const navigateToTicketGrid = () => {
    navigate(Constants.QUERY_TICKETS_URL);
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: "primary.light",
          height: "10rem",
          paddingLeft: "1.875rem",
        }}
      >
        <Typography variant="h1" role="heading" sx={{ paddingTop: "2.5rem", fontSize: "2.25rem", fontWeight: "400" }}>
          {t("COMMON:CREATE_NEW_TICKET")}
        </Typography>
      </Box>
      <Box sx={{ paddingLeft: "30px" }}>
        <Typography variant="h2" sx={{ marginTop: "2.5rem", fontSize: "1.875rem", fontWeight: "400" }}>
          {t("COMMON:TICKET_CREATED_SUCCESS")}
          <CheckCircleIcon
            sx={{ height: "1.5rem", width: "1.5rem", marginLeft: "0.5rem", marginTop: "0.25rem", color: "#6FB269" }}
          />
        </Typography>
        <Typography sx={{ marginTop: "2.5rem", fontSize: "1.125rem", fontWeight: "600" }}>
          {t("COMMON:TICKET_ID")}: {ticketId}
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#313131",
            color: "#ffffff",
            marginTop: "2rem",
            "&: hover": {
              backgroundColor: "#313131",
            },
          }}
          onClick={navigateToTicketGrid}
        >
          {t("COMMON:BACK_TO_TICKETS")}
        </Button>
      </Box>
    </>
  );
};
export default TicketCreatedScreen;