import React from "react";
import { useTranslation } from "react-i18next";
import { InProgressButtons } from "./InProgressButtons";
import TicketGrid from "../../commons/TicketGrid/TicketGrid";
import Constants from "../../../Constants";
import "../../../styles/InProgress.css"; // Contains "In progress" specifc styling for SLA column
import { dateComparator } from "../../commons/Utils";

function ApprovalinProgress() {
  const { t } = useTranslation();
  const cellClassRules = (params) => {
    const { daysSinceLastUpdated } = params.node.data;
    const daysDiff = daysSinceLastUpdated != null ? daysSinceLastUpdated : 0;
    if (daysDiff < 15) {
      return "cell-green";
    }
    if (daysDiff <= 21 && daysDiff >= 15) {
      return "cell-amber";
    }
    return "cell-red";
  };

  const handleNullSla = (params) => {
    const { daysSinceLastUpdated } = params.node.data;
    return daysSinceLastUpdated === undefined ? 0 : daysSinceLastUpdated;
  };

  const columnDefs = [
    {
      field: "daysSinceLastUpdated",
      headerName: t("TICKETS:GRID_COLUMNS.SLA"),
      cellClass: cellClassRules,
      cellRenderer: handleNullSla,
      width: "76px",
    },
    {
      field: "id",
      headerName: t("TICKETS:GRID_COLUMNS.TICKET_NUMBER"),
      flex: 1,
    },
    {
      field: "assignedTo",
      headerName: t("TICKETS:GRID_COLUMNS.ASSIGNED_TO"),
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: t("TICKETS:GRID_COLUMNS.CREATED_ON"),
      sort: "desc",
      comparator: dateComparator,
      sortingOrder: ["desc", "asc"],
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: t("TICKETS:GRID_COLUMNS.UPDATED_ON"),
      sort: "desc",
      comparator: dateComparator,
      sortingOrder: ["desc", "asc"],
      flex: 1,
    },
    {
      field: "createdBy",
      headerName: t("TICKETS:GRID_COLUMNS.CREATED_BY"),
      flex: 1,
    },
    {
      field: "action",
      headerName: t("TICKETS:GRID_COLUMNS.ACTION"),
      pinned: "right",
      cellRenderer: InProgressButtons,
      flex: 1,
    },
  ];

  return (
    <TicketGrid
      columnDefs={columnDefs}
      ticketStatusArray={[Constants.TICKET_STATUS.IN_PROGRESS]}
      ticketSubTypeArray={[Constants.SUB_TYPES.TENANT_ONBOARDING]}
    />
  );
}

export default ApprovalinProgress;
