import { useState } from "react";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableSortLabel from "@mui/material/TableSortLabel";

import { useTranslation } from "react-i18next";

const parentBoxStyle = { maxHeight: "70vh", overflowY: "auto" };
const tableHeadStyle = { position: "sticky", top: "0", backgroundColor: "white" };
const tableHeadRowStyle = { display: "flex", width: "100%" };
const tableBodyRowStyle = { display: "flex", width: "100%" };
const tableBodyCellStyle = { flex: 1 };
const tableHeadCellStyle = { flex: 1, fontWeight: "bold" };

export default function BasicTable({ data, columns }) {
  const { t } = useTranslation();
  const [order, setOrder] = useState("");
  const [orderedBy, setOrderedBy] = useState("");
  const [sortedData, setSortedData] = useState(data);

  function initializeState(column) {
    if (order === "" && orderedBy === "") {
      setOrder(column.sort);
      setOrderedBy(column.dataAttr);
    }
  }

  return (
    <Box style={parentBoxStyle}>
      <Table stickyHeader="true">
        <TableHead style={tableHeadStyle}>
          <TableRow sx={tableHeadRowStyle}>
            {columns.map((column) => {
              initializeState(column);
              return (
                <TableCell sx={tableHeadCellStyle}>
                  {t(`COMMON:${column.name}`)}
                  <TableSortLabel
                    active
                    direction={orderedBy === column.dataAttr ? order : "desc"}
                    hideSortIcon={false}
                    onClick={() => {
                      setOrderedBy(column.dataAttr);

                      if (order != "") {
                        if (order == "asc") {
                          setOrder("desc");
                        } else {
                          setOrder("asc");
                        }
                      }

                      const sortedData = data.sort((a, b) => {
                        if (column.sorter) {
                          return order === "asc" ? column.sorter(a, b, orderedBy) : -column.sorter(a, b, orderedBy);
                        }
                        return order === "desc" ? column.sorter(a, b, orderedBy) : -column.sorter(a, b, orderedBy);
                      });
                      setSortedData(sortedData);
                    }}
                  />
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((row, index) => (
            <TableRow key={index} sx={tableBodyRowStyle}>
              {columns.map((column) => (
                <TableCell sx={tableBodyCellStyle}>
                  {column.formatter ? column.formatter(row[column.dataAttr]) : row[column.dataAttr]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
