import ExpandLess from "@mui/icons-material/ExpandLess";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { styled } from "@mui/material";

const StyledExpandLessIcon = styled(ExpandLess)(({ theme }) => ({
  position: "absolute",
  left: "0.5em",
  height: "12px",
  color: theme.palette.primary.lightest,
}));

const StyledKeyboardArrowRightIcon = styled(KeyboardArrowRightIcon)(({ theme }) => ({
  position: "absolute",
  left: "0.5em",
  height: "12px",
  color: theme.palette.primary.lightest,
}));

function ListItemExpand({ onboardingExpanded }) {
  return (
    <>
      {onboardingExpanded ? (
        <StyledExpandLessIcon fontSize="small" />
      ) : (
        <StyledKeyboardArrowRightIcon fontSize="small" />
      )}
    </>
  );
}

export default ListItemExpand;
