import { useState } from "react";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import LeftMenuItems from "./LeftMenuItems";

import useOutsideClick from "../../hooks/useOutsideClick";
// https://www.telerik.com/blogs/how-to-build-recursive-side-menu-react
const drawerWidth = 272;
const StyledMuiDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "absolute",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: "76px",
    }),
    backgroundColor: theme.palette.primary.dark,
    overflow: "hidden",
  },
}));

function LeftMenu() {
  const [open, setOpen] = useState(false);

  const closeDrawer = () => setOpen(false);
  const outsideClickRef = useOutsideClick(closeDrawer);
  return (
    <StyledMuiDrawer variant="permanent" open={open} ref={outsideClickRef}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <IconButton
          sx={{ padding: "0", marginLeft: "2px", height: "24px", width: "24px" }}
          onClick={() => setOpen((prev) => !prev)}
        >
          {open ? (
            <CloseIcon sx={{ fontSize: "24px", color: "primary.light" }} />
          ) : (
            <MenuIcon sx={{ fontSize: "24px", color: "primary.light" }} />
          )}
        </IconButton>
      </Toolbar>
      <List component="nav" sx={{ height: "100%" }}>
        <LeftMenuItems open={open} setOpen={setOpen} />
      </List>
    </StyledMuiDrawer>
  );
}

export default LeftMenu;
