import { styled } from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

const DetailsActionButton = styled(LoadingButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.darker}`,
  color: theme.palette.primary.darker,
}));

export default DetailsActionButton;
