import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../services/UserContext";
import HttpContext from "../../../services/HttpContext";
import GridActionButton from "../../commons/GridActionButton/GridActionButton";
import { TabContext } from "../../Tabs/CustomTabs";
import constants from "../../../Constants";

export default function UnassignedButtons(props) {
  const { data } = props;
  // 'props' contain useful utilities and info sent by ag-grid
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { activeIndex } = useContext(TabContext);
  const { handleSnackBarChange } = props;
  const { REACT_APP_API_HELPDESK, REACT_APP_API_ASSIGN } = constants;

  const { PostRequest } = useContext(HttpContext);

  // These are the values to send to Assign ticket API
  const ticketId = data?.id;
  const appId = data?.referenceId;

  // This is the email address of current Admin user accessing the portal,
  const {
    user: { user }, // Directly destructuring the email address string
  } = useContext(UserContext);

  const handleNavigation = (readonly) => {
    const state = {
      readOnly: readonly,
      lastActiveTicketGridTabIndex: activeIndex,
    };
    navigate(`${constants.TENANT_ON_BOARDING_URL}/ticket/${ticketId}/${appId}`, { state });
  };

  const assignTicket = async (_ticketId, assignedEmailId) => {
    try {
      const requestBody = {
        actorId: assignedEmailId,
        receiverId: assignedEmailId,
      };
      await PostRequest(`${REACT_APP_API_HELPDESK}/${_ticketId}/${REACT_APP_API_ASSIGN}`, requestBody);
      handleSnackBarChange(true, "Ticket successfully assigned", "success");
      handleNavigation(false);
    } catch (error) {
      handleSnackBarChange(true, "Failed to assign ticket", "failure");
    }
  };

  const handleStartClick = React.useCallback(() => {
    assignTicket(ticketId, user, appId);
  }, [ticketId, user, appId]);

  const handleViewClick = () => handleNavigation(true);

  return (
    <div>
      <div className="unassigned-view-button">
        <GridActionButton variant="outlined" size="small" onClick={handleViewClick}>
          {t("COMMON:VIEW")}
        </GridActionButton>
      </div>
      <div className="unassigned-start-button">
        <GridActionButton variant="outlined" size="small" onClick={handleStartClick}>
          {t("COMMON:START")}
        </GridActionButton>
      </div>
    </div>
  );
}
