// React imports
import React from "react";
import { useTranslation } from "react-i18next";
import { CompletedButtons } from "./CompletedButtons";
import TicketGrid from "../../commons/TicketGrid/TicketGrid";
import Constants from "../../../Constants";
import { dateComparator } from "../../commons/Utils";

function Completed() {
  const { t } = useTranslation();

  const columnDefs = [
    {
      field: "id",
      headerName: t("TICKETS:GRID_COLUMNS.TICKET_NUMBER"),
      flex: 1,
    },
    {
      field: "assignedTo",
      headerName: t("TICKETS:GRID_COLUMNS.ASSIGNED_TO"),
      flex: 1,
    },
    {
      field: "createdBy",
      headerName: t("TICKETS:GRID_COLUMNS.CREATED_BY"),
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: t("TICKETS:GRID_COLUMNS.CREATED_ON"),
      sort: "desc",
      comparator: dateComparator,
      sortingOrder: ["desc", "asc"],
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: t("TICKETS:GRID_COLUMNS.UPDATED_ON"),
      sort: "desc",
      comparator: dateComparator,
      sortingOrder: ["desc", "asc"],
      flex: 1,
    },
    {
      field: "action",
      headerName: t("TICKETS:GRID_COLUMNS.ACTION"),
      pinned: "right",
      cellRenderer: CompletedButtons,
      cellRendererParams: {},
    },
  ];

  return (
    <TicketGrid
      columnDefs={columnDefs}
      ticketStatusArray={[Constants.TICKET_STATUS.RESOLVED, Constants.TICKET_STATUS.CLOSED]}
      ticketSubTypeArray={[Constants.SUB_TYPES.QUERY]}
    />
  );
}

export default Completed;