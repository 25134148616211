export const envColorMap = {
  dev: {
    color: '#313131',
    backgroundColor: '#9A91C6',
    envName: 'Development',
  },
  stb: {
    color: '#313131',
    backgroundColor: '#FF9D37',
    envName: 'INT',
  },
  qa: {
    color: '#313131',
    backgroundColor: '#6FB269',
    envName: 'Demo',
  },
  preprod: {
    color: '#FFFFFF',
    backgroundColor: '#017597',
    envName: 'Pre Production',
  },
};
export const getColors = (env) => {
  return envColorMap[env];
};