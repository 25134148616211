import { Modal, Typography, Box, Button, TextField, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { closeTicket } from "../CreateTicketSection/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import LoadingButton from "@mui/lab/LoadingButton";
const CloseTicketModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { ticketId } = useParams();
  const [comment, setComment] = useState();
  const [message, setMessage] = useState();
  const queryClient = useQueryClient();
  const closeModal = () => {
    onClose();
    setComment("");
  };
  const handleChange = (event) => {
    setComment(event.target.value);
  };
  const handleSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ["Tenant_Provider_Details"] });
    queryClient.invalidateQueries({ queryKey: ["Verifications"] });
    onClose();
  };
  const handleError = () => {
    setMessage(t("COMMON:UNABLE_TO_CLOSE"));
  };
  const { mutateAsync, isLoading } = useMutation(closeTicket, {
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const closeTheTicket = () => {
    const responseData = mutateAsync({ ticketId: ticketId, comment: comment });
    responseData
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          height: "14.375rem",
          width: "37.5rem",
          backgroundColor: "#ffffff",
          padding: "2rem",
          margin: "14% auto",
          position: "relative",
        }}
      >
        <Button onClick={closeModal} sx={{ position: "absolute", top: "0.938rem", right: "4px", color: "#000000" }}>
          <CloseIcon sx={{ height: "1.5rem", width: "1.5rem" }} />
        </Button>
        <Typography variant="h2" fontWeight={600} sx={{ marginBottom: "1.75rem" }}>
          {t("COMMON:CLOSE_TICKET")}
        </Typography>
        <Typography sx={{ marginBottom: "0.75rem" }}>{t("COMMON:PLEASE_ADD_COMMENTS")}</Typography>
        <TextField
          multiline
          rows={2}
          placeholder={t("COMMON:COMMENTS")}
          sx={{
            minWidth: "100%",
          }}
          inputProps={{ maxLength: 500 }}
          value={comment}
          onChange={handleChange}
        />
        <Typography sx={{ color: "red" }}>{message}</Typography>
        <Stack
          direction="row"
          sx={{ marginTop: "1rem", width: "13.125rem", display: "flex", justifyContent: "space-between" }}
        >
          <Button
            variant="contained"
            onClick={closeModal}
            sx={{ backgroundColor: "#ffffff", color: "#000000", "&:hover": { backgroundColor: "#ffffff" } }}
          >
            {t("COMMON:CANCEL")}
          </Button>
          <LoadingButton
            variant="contained"
            sx={{
              backgroundColor: "#313131",
              color: "#ffffff",
              "&: hover": {
                backgroundColor: "#313131",
              },
            }}
            onClick={closeTheTicket}
            disabled={!comment?.trim()}
            loading={isLoading}
          >
            {t("COMMON:CONFIRM")}
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
};
export default CloseTicketModal;
