import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function DetailsTitleContainer(props) {
  const { children } = props;
  return (
    <Box sx={{ height: "100px", backgroundColor: "primary.lighter" }}>
      <Box sx={{ paddingTop: "50px", marginLeft: "50px" }}>
        <Typography variant="h2" fontWeight={500}>
          {children}
        </Typography>
      </Box>
    </Box>
  );
}

export default DetailsTitleContainer;
