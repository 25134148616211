import Link from "@mui/material/Link";

export default function ButtonLink(props) {
  const { onClick, children, ...rest } = props;
  return (
    <Link component="button" variant="body2" onClick={onClick} {...rest} sx={{ color: "primary.darker" }}>
      {children}
    </Link>
  );
}
