import Box from "@mui/material/Box";
import { styled } from "@mui/material";

const AppDetailsContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  backgroundColor: theme.palette.primary.lighter,
}));

export default AppDetailsContentContainer;
